// core
import React from "react";

export const HalfTextHalfImage = ({ slice }) => {
  // console.log("slice data ", slice);

  const isReverse = slice.primary.row_reverse

  const label = slice.primary.label?.text || ''
  const title = slice.primary.title?.text || ''
  const listText = slice.primary.list_text?.text || ''
  const listItems = slice.items || []

  const image = {
    url: slice.primary.image?.url || '',
    alt: slice.primary.image?.alt || ''
  }

  const button = {
    url: slice.primary.button_url?.text || '',
    text: slice.primary.button_text?.text || ''
  }


  return (
      <section className={`${isReverse ? 'flex-row-reverse' : ''} half-text-half-image`}>
        <div className="half-text-half-image__text">
          <div className="half-text-half-image__text-content">
            <p className="half-text-half-image__label">{label}</p>
            <h3 className="half-text-half-image__title">{title}</h3>
            <div>
              <p className="half-text-half-image__list-description">{listText}</p>
              { listItems.length !== 0 && <ul className="half-text-half-image__list"> { listItems.map((item) => { return <li className="half-text-half-image__item" key={item.list_item?.text}>{item.list_item?.text}</li> })} </ul> }
            </div>
            <a href={ button.url } className="button-white half-text-half-image__action-button">{ button.text }</a>
          </div>
        </div>
        <div className="half-text-half-image__image">
          <img src={image.url} alt={image.alt}/>
        </div>
      </section>
  )
}
