// core
import React, {useState, useEffect} from 'react'

// component
import { useConsent } from "./ConsentContext";

const SurveyPage = ({ id, token}) => {
    const surveyScript = "//dnsl4xr6unrmf.cloudfront.net/js/campaign.js"

    const [segmentID, setSegmentID] = useState("")
    const [ wyngScriptLoaded, setWyngScriptLoaded ] = useState(false)
    const consentAccepted = useConsent()

    useEffect(() => {
        // append survey script
        const script = document.createElement('script')
        script.src = surveyScript
        script.async = true
        document.body.appendChild(script)

        document.body.setAttribute('data-page', 'wyng-survey')

        script.onload = () => {
            const waitWindowWyngObj = () => {
                if(typeof window.wyng !== "undefined"){
                    setWyngScriptLoaded(true)
                }else{
                    setTimeout(waitWindowWyngObj, 500)
                }
            }
            waitWindowWyngObj()
        }
    }, [])

    useEffect(() => {
        if(segmentID !== "" && typeof window.wyng !== "undefined"){
            const setUserIdAndFields = () => {
                window.wyng[id.text].setFields({'segment_anonymous_id': segmentID})
            }

            if(document.querySelector('.embed-survey form .external_user_id input[type="hidden"]')) {
                setUserIdAndFields()
            }else {
                // Age gate
                const ageGateButtons = document.querySelectorAll('.embed-survey .experience-button')
                const handleAgeGateBtnEvent = () => {
                    setUserIdAndFields()
                    ageGateButtons.forEach((item) => { item.removeEventListener('click', handleAgeGateBtnEvent)})
                }

                ageGateButtons.forEach((item) => { item.addEventListener('click', () => { setTimeout(handleAgeGateBtnEvent, 1000) })})

                return () => {
                    ageGateButtons.forEach((item) => { item.removeEventListener('click', handleAgeGateBtnEvent)})
                }
            }
        }
    }, [segmentID, wyngScriptLoaded, id])

    useEffect(() => {

        const waitWindowAnalyticsObj = () => {
          if(typeof window !== "undefined"){
            // eslint-disable-next-line react-hooks/exhaustive-deps
            let analyticsLocalObj = window.analytics
            if (analyticsLocalObj && consentAccepted) {
              setSegmentID(analyticsLocalObj.user().anonymousId())
            } else {
              setTimeout(waitWindowAnalyticsObj, 250)
            }
          }
        }

        waitWindowAnalyticsObj()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consentAccepted])

    return(
        <div
            className='wyng-experience embed-survey py-lg-5'
            data-wyng-id={id.text}
            data-wyng-token={token.text}
        >
        </div>
    )
}

export default SurveyPage