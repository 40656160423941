// core
import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { SliceZone } from '@prismicio/react'

// components
import { Seo } from "../components/Seo";
import Layout from "../components/Layout";
import { components } from "../slices/event-details-page";

// EventDetailsPage page template to use with slices
const EventDetailsPage = ({data}) => {
  const pageContent = data.prismicEventDetailsPage
  const pageData = data.prismicEventDetailsPage.data

  const { body: slices } = data.prismicEventDetailsPage.data

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  // console.log(pageContent, activeDoc)

  return (
      <Layout currentPage={pageContent.uid} activeDocMeta={activeDoc}>
        <Seo
            title={pageData.meta_title?.text }
            description={pageData.meta_description?.text}
            image={pageData.social_card?.url}
            activeDocMeta={activeDoc}
        />
        <SliceZone components={components} slices={slices} context={{data: pageContent, activeDoc}}/>
      </Layout>
  );
}

export const query = graphql`
query EventDetailsPageQuery ($uid: String, $id: String, $lang: String) {
  prismicEventDetailsPage (uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
    _previewable
    alternate_languages {
      id
      lang
      type
      uid
    }
    data {
      body {
        ... on PrismicEventDetailsPageDataBodyBasicBanner {
          id
          slice_type
          slice_label
          primary {
            banner {
              thumbnails {
                mobile {
                  alt
                  url
                }
              }
              alt
              url
            }
          }
        }
        ... on PrismicEventDetailsPageDataBodyEventDetails {
          id
          primary {
            date {
              richText
              text
            }
            location {
              richText
              text
            }
            title {
              text
              richText
            }
            closed_date {
              richText
              text
            }
            cta_button_link {
              url
            }
            facebook_link {
              url
            }
            twitter_link {
              url
            }
            copy_link {
              url
            }
            cta_button_text {
              richText
              text
            }
            image {
              url
              alt
            }
            learn_more_button_link {
              url
            }
            learn_more_button_text {
              text
              richText
            }
            show_cta_block
          }
          slice_type
        }
        ... on PrismicEventDetailsPageDataBodyBasicTextContent {
          id
          items {
            paragraph {
              text
              richText
            }
          }
          primary {
            title {
              text
              richText
            }
          }
          slice_type
        }
        ... on PrismicEventDetailsPageDataBodyHalfTextHalfImage {
          id
          slice_type
          items {
            list_item {
              text
              richText
            }
          }
          primary {
            row_reverse
            title {
              text
              richText
            }
            label {
              text
              richText
            }
            list_text {
              text
              richText
            }
            image {
              url
            }
            button_link {
              url
            }
            button_text {
              text
            }
          }
        }
        ... on PrismicEventDetailsPageDataBodyAdPanel {
          id
          primary {
            background_image {
              url
              thumbnails {
                mobile {
                  url
                }
              }
            }
            button_link {
              url
            }
            button_text {
              text
            }
            title {
              text
              richText
            }
          }
          slice_type
        }
        ... on PrismicEventDetailsPageDataBodyPromoModule {
          id
          slice_type
          slice_label
          items {
            image {
              url
              alt
            }
            button_link {
              url
              link_type
            }
            button_text {
              text
            }
            subtitle {
              text
              richText
            }
          }
          primary {
            title {
              text
            }
          }
        }
      }
      meta_description {
        richText
        text
        html
      }
      meta_title {
        html
        richText
        text
      }
      social_card {
        url
        alt
      }
    }
    uid
    type
    lang
    id
    url
  }
} `

export default withPrismicPreview(EventDetailsPage)