export const hexToRGB = (hex, alpha = 1) => {
  // Remove the hash if it's there
  hex = hex.replace('#', '');

  // Parse the hexadecimal color into its individual RGB components
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Construct the RGBA color string
  const colorString = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';

  return colorString;
}

// Example usage
// var hexColor = '#FF0000'; // Red color
// var rgbaColor = hexToRGB(hexColor);
// console.log(rgbaColor); // Output: rgba(255, 0, 0, 1)

// var hexColorWithAlpha = '#00FF00'; // Green color with alpha
// var rgbaColorWithAlpha = hexToRGB(hexColorWithAlpha, 0.5);
// console.log(rgbaColorWithAlpha); // Output: rgba(0, 255, 0, 0.5)