// core
import React, {useEffect, useState} from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { SliceZone } from '@prismicio/react'
import { useLocation } from '@reach/router'

// components
import { Seo } from "../components/Seo";
import Layout from "../components/Layout";
import { components } from "../slices/generic-page";
import { PageCustomStyle } from '../components/PageCustomStyle';
import {scroller} from "react-scroll";
//constants
import { homepageSnippet,ourStorySnippet } from '../constants/dataSnippets'
import useWindowSize from '../hooks/useWindowSize'
// Generic page template to use with slices
const GenericPage = ({data}) => {
  const pageContent = data.prismicGenericPage
  const pageData = data.prismicGenericPage.data

  const { body: slices } = data.prismicGenericPage.data

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const location = useLocation()
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  const showNewslettersPopUp = pageData.email_subscription_pop_up
  const header_navigation = pageData.header_navigation
  // console.log(pageContent, activeDoc)
  // console.log('pageData', pageData)
  // console.log(slices)
  const homepageString = JSON.stringify(homepageSnippet, null, 2);
  const ourStoryString = JSON.stringify(ourStorySnippet, null, 2);
  useEffect(()=>{
    // 👇 add attr to body element
    document.body.setAttribute('data-page', 'generic-template')

    return () => {
      // 👇 removing attr from body element
      // when the component unmounts
      document.body.removeAttribute('data-page')
    }
  },[])

  useEffect(() => {
    window.onload = () => setIsPageLoaded(true)
  }, []);

  useEffect(() => {
    if(location.hash){
      const id = location.hash.replace('#', '');
      scroller.scrollTo(`anchor-link-${id}`, {
        duration: 1000,
        delay: 50,
        smooth: true,
      });
    }
  }, [location, isPageLoaded])

  const {width} = useWindowSize()
  const section = {
    mobileBgImg: pageData?.mobile_background_image?.url || '',
    desktopBgImg: pageData?.background_image?.url || '',
  }

  const bodyBackgroundImageStyles = {
    backgroundImage: section.desktopBgImg ? `${width >=991 ? `url(${section.desktopBgImg})` : `url(${section?.mobileBgImg})`}` : null,
    backgroundSize:"100%",
    backgroundColor: section.desktopBgImg ? "#000000" : null,
  }

  return (
    <Layout currentPage={pageContent.uid} activeDocMeta={activeDoc}
      noSignUpPopup={!showNewslettersPopUp} className={`generic-template ${header_navigation==="Hidden"? "hidden-Navbar":''}`} header_navigation={header_navigation}
      style={bodyBackgroundImageStyles}
    >
          {pageData.custom_css?.text && <PageCustomStyle cssString={pageData.custom_css?.text} />}
          <Seo
              title={pageData.meta_title?.text }
              description={pageData.meta_description?.text}
              image={pageData.social_card?.url}
              activeDocMeta={activeDoc}
              currentPage={pageContent?.uid}
          >
             {pageContent?.uid == "homepage" && <script type="application/ld+json">{homepageString}</script> }
             {pageContent?.uid == "our-story" && <script type="application/ld+json">{ourStoryString}</script> }
          </Seo>
          <SliceZone components={components} slices={slices} context={{data: pageContent, activeDoc}} />
        </Layout>
  );
}

export const query = graphql`
query GenericPageQuery($uid: String, $id: String, $lang: String){
    prismicGenericPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        url
        uid
        type
        id
        lang
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          meta_title {
            text
          }
          meta_description {
            text
          }
          social_card {
            url
          }
          email_subscription_pop_up
          header_navigation
          background_image {
            url
            alt
          }
          mobile_background_image{
            url
            alt
          }
          body {
          ... on PrismicSliceType {
            slice_type
          }
          ... on PrismicGenericPageDataBodyInstagramGallery {
            id
            slice_type
            primary {
              anchor_link{
                text
              }
              title {
                text
              }
              title_text_color
            }
          }
          ...PageDataBodyHero
          ...PageDataBodyYoutubeVideoSection
          ...PageDataBodyFullwidthTextWithButton
          ...PageDataBodySplitBanner
          ...PageDataBodyFindNearYou
          ...PageDataBodyContentPanel
          ...PageDataBodyFullwidthSlider
          ...PageDataBodyAboutProduct
          ...PageDataBodyProductRange
          ...PageDataBodyProductCarousel
          ...PageDataBodyProductTastingNotes
          ...PageDataBodyMasonryGallery
          ...PageDataBodyCrosslink
          ...PageDataBodyTabbedRange
          ...PageDataBodyOvalImage
          ...PageDataBodyPolaroidPhotoGallery
          ...PageDataBodyClickMoreBanner
          ...PageDataBodyIframe
          ...PageDataBodyMembership
          ...PageDataBodyWyngForm
          ...PageDataBodyFullWidthSectionDivider
          ...PageDataBodyTickerTape
          ...PageDataBodyTile
          ...PageDataBodyEvent
          ...PageDataBodyAccordion
          }

          custom_css {
            html
            text
          }
        }
    }
}
`
export default withPrismicPreview(GenericPage)