import { COLOR } from "../../constants/colors";
import { hexToRGB } from "./hexToRGB";

export const getButtonCustomStyles = (item) => {

  const bgColorAlpha = item.bgT != null ? (item.bgT / 100) : 1;
  const convertedColorAlpha = hexToRGB(item.bg || COLOR.WHITE,bgColorAlpha)

  const bgHoverColorAlpha = item.bgHoverT != null ? (item.bgHoverT / 100) : 1;
  const convertedHoverColorAlpha = hexToRGB(item.bgHover || COLOR.BLACK,bgHoverColorAlpha)

  const ctaButtonStyle = {
    color: item.color || COLOR.BLACK,
    backgroundColor: convertedColorAlpha,
    border: item.border ? `2px solid ${item.border}` : `2px solid ${COLOR.BLACK}`,
    '--cta_button_hover_text_color': item.colorHover || COLOR.WHITE,
    '--cta_button_hover_bg_color': convertedHoverColorAlpha,
    '--cta_button_hover_border': item.borderHover ? `2px solid ${item.borderHover}` : `2px solid ${COLOR.BLACK}`,
    borderRadius:item.borderRadius ? `${item.borderRadius}px` :  ``,
    padding: item?.padding ? `${item?.padding}`: ``,
  }

  return { ctaButtonStyle }

}