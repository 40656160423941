import React, {useEffect, useRef} from 'react'
import {graphql} from "gatsby";
import {PrismicRichText} from "@prismicio/react";

import useWindowSize from '../../hooks/useWindowSize';
import {downloadMedia, getButtonCustomStyles, getFontSize} from "../../assets/js";

import {COLOR} from "../../constants/colors";
import {AnchorLink} from "../../components/AnchorLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

import { useLocation } from "@reach/router";
import { useConsent } from "../../components/ConsentContext";

// data-layer
import { setDownloadClickedEvent } from "../../assets/js/data-layer";

const Slide = ({slide, ind}) => {
  const location = useLocation()
  const consentAccepted = useConsent()
  // console.log("FullWidthTextWithButton Slice", item)
  const {width} = useWindowSize()
  const playerRef = useRef()

  // Toggles
  const showSubTitle = slide.show_subtitle
  const showTitle = slide.show_title
  const showParagraph = slide.show_paragraph
  const showCTAButton = slide.show_cta_button

  // Links
  const isDownloadLink = slide.cta_download_button
  const isTargetBlank = slide.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Text
  const subTitle = slide.subtitle?.richText || []
  const subTitleTextColor = slide.subtitle_text_color || COLOR.WHITE
  const subTitleFontSizeDesktop = slide.subtitle_font_size_desktop || '38'
  const subTitleFontSizeMobile = slide.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

  const title = slide.title?.richText || []
  const titleTextColor = slide.title_text_color || COLOR.WHITE
  const titleTextFontSizeDesktop = slide.title_font_size_desktop || '66'
  const titleTextFontSizeMobile = slide.title_font_size_mobile || '40'
  const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)

  const paragraph = slide.paragraph?.richText || []
  const paragraphTextColor = slide.paragraph_text_color || COLOR.WHITE
  const paragraphFontSizeDesktop = slide.paragraph_font_size_desktop || '24'
  const paragraphFontSizeMobile = slide.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  // CTA Button
  const ctaButton = {
    text: slide.cta_button_text?.text || '',
    url: slide.cta_button_link?.url || ''
  }

  const ctaDownloadButtonFileName = slide.cta_download_file_name || ''
  const ctaButtonDownloadLink = slide.cta_download_button_file?.url || ''

  // CTA Styles
  const buttonColorSet = {
    color: slide.cta_button_text_color,
    bg: slide.cta_button_bg_color ,
    bgT: slide.cta_button_bg_color_transparency,
    border: slide.cta_button_border_color,
    colorHover: slide.cta_button_text_color_hover,
    bgHover: slide.cta_button_bg_color_hover,
    bgHoverT: slide.cta_button_bg_color_hover_transparency,
    borderHover: slide.cta_button_border_color_hover ,
    borderRadius: slide.cta_button_border_radius || "0",
  }


  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)

  // Section

  const section = {
    mobileBgImg: slide.image?.thumbnails?.mobile.url || '',
    desktopBgImg: slide.image?.url || '',
  }

  const sectionStyles = {
    backgroundImage: section.desktopBgImg ? `${width <= 768 ? `url(${section.mobileBgImg})` : `url(${section.desktopBgImg})`}` : null,
  }

  useEffect(()=>{
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current?.play()
    });

  },[])

  return (
      <div className="full-width-slider__slide" style={sectionStyles} key={ind}>
        <div className='full-width-slider__text-wrap'>
          { showSubTitle && <h4 className='full-width-slider__subtitle'>
            { subTitle.map((row) => {
              return  <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>})
            }
          </h4> }

          { showTitle && <h3 className='full-width-slider__title'>
            { title.map((row) => {
              return  <span key={row.text} style={titleStyles}>
                <PrismicRichText field={[row]}/><br/>
              </span>})
            }
          </h3> }

          { showParagraph && <p className='full-width-slider__paragraph'>
            { paragraph.map((row) => {
              return  <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>})
            }
          </p>}

          {showCTAButton && <>
            {!isDownloadLink &&
                <a href={ctaButton.url} title={ctaButton.text} className="full-width-slider__cta-button mt-4"
                   style={ctaButtonStyle} target={target}>
                  {ctaButton.text}
                  {isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
                </a>}

            {isDownloadLink &&
                <button title={ctaButton.text} className="full-width-slider__cta-button" style={ctaButtonStyle}
                        onClick={() => {
                          downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                          // 'download_clicked' data layer event
                          setDownloadClickedEvent({
                            fileName: ctaDownloadButtonFileName,
                            clickUrl: ctaButtonDownloadLink,
                            clickText: ctaButton.text,
                            pageUrl: location.href
                          }, consentAccepted)
                        }}
                >
                  {ctaButton.text}
                  <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                </button>}
          </>}
        </div>
        {slide.isVideo && <video className='full-width-slider__video'
                ref={playerRef}
                src={slide.videoSrc}
                width="100%"
                height="100%"
                playsInline
                autoPlay
                loop
                muted/>}
      </div>
  )
}

export const FullWidthSlider = ({ slice }) => {
  // console.log("FullWidthSlider Slice", slice)
  const { width } = useWindowSize()

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  const contentTypeImage = slice.primary.content_type

  const slides = slice?.items || []

  if(!contentTypeImage) {

    // add video files to slide object
    slides.map((slide, i)=>{
      const githubVideoFilename = width < 768 ? slide?.github_video_filename_mobile : slide?.github_video_filename_desktop;
      const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
      const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''
      slide.isVideo = true
      slide.videoSrc = video
      return slide
    })
  }

  return (
      <section className="full-width-slider" id={`full-width-slider-${sectionId}`}>
        { anchorLink && <AnchorLink link={anchorLink}/> }

        <div>
        {slides.map(( slide, index )=>{
          return <Slide slide={slide} ind={index} key={index}/>
        })}
        </div>
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyFullwidthSlider on PrismicGenericPageDataBodyFullwidthSlider  {
    id
    primary {
      content_type
      section_id{
        text
      }
      anchor_link{
        text
      }
    }
    items {
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_button_link {
        url
      }
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
      image {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      github_video_filename_mobile
      github_video_filename_desktop
      show_cta_button
      show_paragraph
      show_subtitle
      show_title
      show_subtitle
      subtitle{
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
    }
  }
`;