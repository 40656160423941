import React, { useEffect, useRef } from 'react'
import { Link, graphql } from 'gatsby'
import Jump from 'react-reveal/Jump';
import { PrismicRichText } from "@prismicio/react";
import useOnScreen from '../../hooks/useOnScreen';
import { AnchorLink } from "../../components/AnchorLink";
// assets
import useWindowSize from '../../hooks/useWindowSize';
import { getFontSize } from "../../assets/js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

// constants
import { COLOR } from "../../constants/colors";

export const ClickMoreBanner = ({ slice }) => {
  // console.log("ClickMoreBanner Slice", slice)
  const { width } = useWindowSize()
  const ref = useRef()
  const isVisible = useOnScreen(ref)
  const isBrowser = typeof window !== "undefined"

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showLogo = slice.primary.show_logo_image
  const showTitle = slice.primary.show_title
  const showParagraph = slice.primary.show_paragraph
  const showArrow = slice.primary.show_cta_arrow
  const enableTransparentHeaderWhenVisible = slice.primary.enable_transparent_header_when_visible

  // Text
  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextFontSizeDesktop = slice.primary.title_font_size_desktop || '66'
  const titleTextFontSizeMobile = slice.primary.title_font_size_mobile || '40'
  const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)

  const paragraph = slice.primary.paragraph?.richText || []
  const paragraphTextColor = slice.primary.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = slice.primary.paragraph_font_size_desktop || '24'
  const paragraphFontSizeMobile = slice.primary.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  useEffect(() => {
    if(isBrowser && enableTransparentHeaderWhenVisible) {
      const header = document.querySelector('header');
      if (header) {
        if (isVisible) {
          header.classList.add('js-transparent-slice-visible');
        } else {
          header.classList.remove('js-transparent-slice-visible');
        }
      }
    }
  }, [isVisible]);

  useEffect(()=> {
    const htmlElement = document.querySelector('html')
    htmlElement.style.scrollBehavior = 'smooth'

    return () => htmlElement.style.scrollBehavior = 'unset'
  },[])

  return (
    <section className='click-more-banner' id={`click-more-banner-${sectionId}`}>
      { anchorLink && <AnchorLink link={anchorLink}/> }

      <div className='click-more-banner__bg'>
        <img src={width < 768 ? slice.primary.bg_image.url : slice.primary.bg_image.thumbnails.mobile.url} alt={width < 768 ? slice.primary.bg_image.alt : slice.primary.bg_image.thumbnails.mobile.alt} />
      </div>
      <div className='click-more-banner__text-container'>
        {showLogo && <img
        className='click-more-banner__logo'
        src={width > 768 ? slice.primary.logo_image.url : slice.primary.logo_image.thumbnails.mobile.url}
        alt={width > 768 ? slice.primary.logo_image.alt : slice.primary.logo_image.thumbnails.mobile.alt}
        />}
        { showTitle && <h3 className='click-more-banner__title'>
          { title.map((row) => {
            return  <span key={row.text} style={titleStyles}>
              <PrismicRichText field={[row]}/><br/>
            </span>})
          }
        </h3> }
        { showParagraph && <p className='click-more-banner__paragraph'>
          { paragraph.map((row) => {
            return  <span key={row.text} style={paragraphStyles}>{row.text}{paragraph.length > 1 ? <br/> : null}</span>})
          }
        </p>}
        {showArrow && 
          <div className='click-more-banner__arrow'>
            <Link
              to="#nextcontent"
            >
              <Jump forever={true}>
                <FontAwesomeIcon icon={faChevronDown} className="fa-solid"/>
              </Jump>
            </Link>
          </div>
        }
      </div>
      {showArrow && <div id='nextcontent'></div>}
      <div ref={ref} id='endcontentref'></div>
    </section>
  );
}

export const fragment = graphql`
  fragment PageDataBodyClickMoreBanner on PrismicGenericPageDataBodyClickMoreBanner  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      enable_transparent_header_when_visible
      bg_image {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
      show_logo_image
      logo_image {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
      show_paragraph
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
      show_title
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      show_cta_arrow
    }
  }
`;