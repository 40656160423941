import React from 'react'

export const LocatorHero = ({slice}) => {
  const banner = slice.primary.banner?.url || ''
  const data = slice.primary

  return <>
    <section className='prod-locator__hero' style={{backgroundImage: `url(${banner})`}}>
    </section>
    <section className='prod-locator__key-message'>
      <p className='prod-locator__key-message-text'>
        {(data.title)? data.title.text : ''}
      </p>
    </section>
  </>
}