import React, { useState, useEffect, useContext } from "react";
import { useCookies } from "react-cookie";
import PreloaderContext from './PreloaderContext';

import ImgWithFallback from "./ImgWithFallback";
import whiteclawLogoSrc from "../assets/images/logo-blk.webp";
import whiteclawLogoFallbackSrc from "../assets/images/logo-blk.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const GeoGate = () => {
  const IPAPI_KEY = process.env.GATSBY_IPAPI_KEY;
  if (!IPAPI_KEY) {
    console.warn("Missing GATSBY_IPAPI_KEY in environment variables");
  }
  const IPAPI_URL = IPAPI_KEY ? `https://ipapi.co/country_code/?key=${IPAPI_KEY}` : 'https://ipapi.co/country_code/';
  const USE_COOKIES = true;
  const [userGeoCountryCode, setUserGeoCountryCode] = useState("en-US");
  const [userGeoCountryName, setUserGeoCountryName] = useState("US");
  const [userGeoCountryURL, setUserGeoCountryURL] = useState(null);
  const [cookies, setCookie] = useCookies();
  const { setIsCustomLoading, setIsPreloaderActive } = useContext(PreloaderContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCountry, setShowCountry] = useState(true);

  const generateURL = (country, lang = '') => `https://${country}.whiteclaw.com/${lang}`;

  // For multiple countries selec the fallback order. 
  // If "en-" cannot be found, it will default to the 
  // first occurance of the country code
  const countryLanguageURLs = {
    "en-US": {
      name: "United States (en)",
      url: generateURL("www"),
    },
    "es-US": {
      name: "Estados Unidos (es)",
      url: generateURL("www", "es"),
    },
    "en-CA": {
      name: "Canada (en)",
      url: generateURL("ca"),
    },
    "fr-CA": {
      name: "Canada (fr)",
      url: generateURL("ca", "fr"),
    },
    "en-GB": {
      name: "United Kingdom (en)",
      url: generateURL("uk"),
    },
    "en-IE": {
      name: "Ireland (en)",
      url: generateURL("ie"),
    },
    "nl-NL": {
      name: "Nederland (nl)",
      url: generateURL("nl", "nl"),
    },
    "en-NL": {
      name: "Netherlands (en)",
      url: generateURL("nl"),
    },
    "en-BE": {
      name: "Belgium (en)",
      url: generateURL("be"),
    },
    "fr-BE": {
      name: "Belgique (fr)",
      url: generateURL("be", "fr"),
    },
    "nl-BE": {
      name: "Belgie (nl)",
      url: generateURL("be", "nl"),
    },
    "en-FI": {
      name: "Finland (en)",
      url: generateURL("fi"),
    },
    "de-AT": {
      name: "Österreich (de)",
      url: generateURL("at"),
    },
    "en-AT": {
      name: "Austria (en)",
      url: generateURL("at", "en"),
    },
    "fr-CH": {
      name: "Suisse (fr)",
      url: generateURL("ch", "fr"),
    },
    "de-CH": {
      name: "Schweiz (de)",
      url: generateURL("ch"),
    },
    "en-CH": {
      name: "Switzerland (en)",
      url: generateURL("ch", "en"),
    },
    "de-DE": {
      name: "Deutschland (de)",
      url: generateURL("de"),
    },
    "en-DE": {
      name: "Germany (en)",
      url: generateURL("de", "en"),
    },
    "sv-SE": {
      name: "Sverige (sv)",
      url: generateURL("se", "sv"),
    },
    "en-SE": {
      name: "Sweden (en)",
      url: generateURL("se"),
    },
    "en-AU": {
      name: "Australia (en)",
      url: generateURL("au"),
    },
  };

  const geoLabelsEN = {
    intro1: "It looks like you're visiting from ",
    intro2: "Update your location?",
    dropdownLabel: "Change your location",
    buttonStayOn: "Stay on United States",
    buttonUpdate: "Update",
    footerText: "Please Drink Responsibly. All Registered Trademarks, used under license by White Claw Seltzer Works, Chicago, IL",
  };

  const geoLabelsES = {
    intro1: "Parece que tu idioma preferido es el español. ",
    intro2: "¿Cambia tu idioma?",
    dropdownLabel: "Cambia tu idioma:",
    buttonStayOn: "Quedarse en Estados Unidos (en)",
    buttonUpdate: "Actualiza",
    footerText: "Por favor bebe responsablemente. Todas las marcas registradas, son utilizadas bajo licencia por White Claw Seltzer Works, Chicago, IL,",
  };

  const [geoLabels, setGeoLabels] = useState(geoLabelsEN);

  // Get Date to set the cookie expiration
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 7);
  const aWeekFromCurrentDate = currentDate;

  const handleStayOnUS = () => {
    setUserGeoCountryCode("en-US");
    setIsModalOpen(false);
    if (USE_COOKIES) {
      setCookie("geoUserPref", "en-US", { expires: aWeekFromCurrentDate, path: "/" });
    }
  };

  useEffect(() => {
    // Set the preloader to manual control
    setIsCustomLoading(true);
  }, []);

  useEffect(() => {
    // Show the modal if the user's geo country code is not "en-US" and the user has not set a preference for "en-US" in their cookies
    if (userGeoCountryCode !== "en-US" && (!USE_COOKIES || cookies?.geoUserPref !== "en-US")) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
    
    setIsPreloaderActive(false);
    setIsCustomLoading(false);
  }, [userGeoCountryCode, cookies?.geoUserPref]);

  useEffect(() => {
    const browserLanguageRaw = navigator.language || (navigator.languages && navigator.languages[0])  || navigator.userLanguage || navigator.browserLanguage || 'en';
    const userLanguage = browserLanguageRaw.includes('-') ? browserLanguageRaw.split('-')[0] : browserLanguageRaw;
    // Special case: If the user is in Canada and speaks Quebec French, set to Canada French
    const isQuebecFrench = (countryCode, userLanguage) => countryCode === "CA" && userLanguage === "fr-CA";
    // Special case: If the user is in the US and speaks any type of Spanish, set to USA Spanish
    const isUSASpanish = (countryCode, userLanguage) => countryCode === "US" && userLanguage.startsWith("es");
    // If the user's browser language is English and an English language version exists for the geolocated country, select it
    const isEnglishVersionExists = (countryCode, userLanguage, countryCodes) => userLanguage.startsWith("en") && countryCodes.includes(`en-${countryCode}`);
    // If the user's full browser language matches a language in the geolocated country, select it
    const isFullBrowserLanguageMatch = (userLanguage, countryCodes) => countryCodes.includes(userLanguage);
    // If the user's browser language combined with the geolocated country matches a language in the countryCodes, select it
    // Add a condition to exclude "fr-CA" when the browser language is "fr" and the geolocation is "CA"
    const isBrowserLanguageCountryMatch = (countryCode, userLanguage, countryCodes) => !(userLanguage === "fr" && countryCode === "CA") && countryCodes.includes(`${userLanguage}-${countryCode}`);

    const getLanguageCountryCode = (countryCode, userLanguage, countryCodes) => {
      if (isQuebecFrench(countryCode, browserLanguageRaw)) {
        return "fr-CA";
      } else if (isUSASpanish(countryCode, userLanguage)) {
        return "es-US";
      } else if (isEnglishVersionExists(countryCode, userLanguage, countryCodes)) {
        return `en-${countryCode}`;
      } else if (isFullBrowserLanguageMatch(userLanguage, countryCodes)) {
        return userLanguage;
      } else if (isBrowserLanguageCountryMatch(countryCode, userLanguage, countryCodes)) {
        return `${userLanguage}-${countryCode}`;
      } else {
        // If no match is found, default to the first occurrence of the geolocated country in the source object
        return countryCodes[0];
      }
    };

    fetch(IPAPI_URL)
      .then((response) => {
        if (!response.ok) {
          console.warn(`HTTP error! status: ${response.status}`);
          return;
        }
        return response.text();
      })
      .then((countryCode) => {
        let languageCountryCode;

        // Get all country codes for the geolocated country
        const countryCodes = Object.keys(countryLanguageURLs).filter(code => code.endsWith(`-${countryCode}`));

        // Get the language country code based on the user's browser language and the geolocated country
        languageCountryCode = getLanguageCountryCode(countryCode, userLanguage, countryCodes);

        if (languageCountryCode === "es-US") {
          setGeoLabels(geoLabelsES);
          setShowCountry(false);
        } else {
          setGeoLabels(geoLabelsEN);
          setShowCountry(true);
        }

        const languageData = countryLanguageURLs[languageCountryCode];

        if (languageData) {
          setUserGeoCountryCode(languageCountryCode);
          setUserGeoCountryName(languageData.name);
          setUserGeoCountryURL(languageData.url);
        }
      })
      .catch((error) => {
        console.error("IPAPI fetching error:", error);
      })
  }, []);

  return (
    isModalOpen && (
      <div className="modal modal-geo-gate">
        <div className="geo-gate">
          <div className="geo-gate__content">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <ImgWithFallback
                  classNameImg={"geo-gate__logo"}
                  src={whiteclawLogoSrc}
                  fallbackSrc={whiteclawLogoFallbackSrc}
                  alt="White Claw Logo"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-0">
                <h1 className="geo-gate__intro">
                  <span className="geo-gate__intro-prefix">{geoLabels.intro1}</span>
                  { showCountry && <span className="geo-gate__intro-country">{userGeoCountryName.replace(/ \(\w{2}\)$/, "")}.</span>}
                  <span className="geo-gate__intro-suffix">{geoLabels.intro2}</span>
                </h1>
              </div>
            </div>
            <div className="row justify-content-center align-items-end">
              <div className="col-12 col-lg-8 px-0">
                <label htmlFor="geo-gate-select" className="geo-gate__select-label">
                  {geoLabels.dropdownLabel}
                </label>
                <div className="geo-gate__select-wrapper">
                  <select
                    value={userGeoCountryCode}
                    onChange={(e) => {
                      setUserGeoCountryCode(e.target.value);
                      setUserGeoCountryURL(countryLanguageURLs[e.target.value].url);
                    }}
                    className="geo-gate__select-input"
                    id="geo-gate-select"
                  >
                    {Object.entries(countryLanguageURLs).map(([code, data]) => (
                      <option key={code} value={code}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                  <FontAwesomeIcon icon={faChevronDown} className="fa-solid geo-gate__icon-select" aria-hidden="false" />
                </div>
              </div>
              <div className="col-12 col-lg px-0 pl-lg-2">
                <div className="geo-gate__button-update">
                  <a href={userGeoCountryURL}>{geoLabels.buttonUpdate}</a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-0">
                <button onClick={() => handleStayOnUS()} className="geo-gate__button-stayon">
                  <span>{geoLabels.buttonStayOn}</span>
                  <FontAwesomeIcon icon={faChevronRight} className="fa-solid geo-gate__icon-stayon" aria-hidden="false" />
                </button>
              </div>
            </div>
            <div className="geo-gate__terms">
              <p className="geo-gate__terms-text">{geoLabels.footerText}</p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default GeoGate;