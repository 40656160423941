// core
import React, { useEffect } from 'react'

function YouTubeModal({ closeModal, modalDetails, lang }) {

  useEffect(() => {
    document.querySelector('body').classList.add('modal-open')
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      document.querySelector('body').classList.remove('modal-open')
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleKeyDown = event => {
    if (event.code === 'Escape') {
      closeModal();
    }
  };

  const handleOnOverlayClick = event => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (<div className='fullscreen-modal'>
        <div className='modal video-popup'  onClick={handleOnOverlayClick}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-close" data-dismiss="modal" onClick={closeModal}>
                  <span className="modal-close__text">Close</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="modal-close__icon">
                    <path d="M0 0h24v24H0V0z" fill="none"></path>
                    <path
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                  </svg>
                </div>
              </div>
              <div className="modal-body">
                <div className="embed-responsive-item embed-responsive-16by9">
                  <iframe
                      id="video"
                      className="w-100"
                      title='White Claw'
                      src={`${modalDetails}?autoplay=1&amp;modestbranding=1&amp;showinfo=0&amp;rel=0`}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      allowFullScreen
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default YouTubeModal;