import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { useLocation } from '@reach/router'

import {HREFLANG_ALT, LANG, LANGUAGE_COUNTRY_LIST} from '../constants/languages'

export const Seo = ({ description, title, image, activeDocMeta = { alternateLanguages: null } , children, currentPage = null }) => {
  const location = useLocation()

  const  { site , featuredImage } = useSiteMetadata()

  const ogImage = featuredImage && featuredImage?.childImageSharp?.gatsbyImageData

  const lang  = activeDocMeta?.lang ? activeDocMeta?.lang : LANG.EN

  let modifiedPathname = location.pathname;

  if (currentPage !== "homepage") {
    if (!modifiedPathname.endsWith("/")) {
      modifiedPathname += "/";
    }
  } else if (modifiedPathname.endsWith("/")) {
    modifiedPathname = modifiedPathname.slice(0, -1);
  }
  if ((modifiedPathname === '' || modifiedPathname.length == 0) && currentPage == "homepage") {
    modifiedPathname = '/'
  }
  const seo = {
    siteUrl: site.siteMetadata.siteUrl,
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    ogTitle: title || site.siteMetadata.title,
    ogDescription: description || site.siteMetadata.description,
    ogImage: image || `${site.siteMetadata.siteUrl}${ogImage?.images.fallback.src}`,
    ogUrl: site.siteMetadata.siteUrl,
    ogImageSecureUrl: image || `${site.siteMetadata.siteUrl}${ogImage?.images.fallback.src}`,
    twitterCard: site.twitterCard,
    twitterSite :site.twitterSite,
    twitterTitle: title || site.siteMetadata.title,
    twitterImage: image || `${site.siteMetadata.siteUrl}${ogImage?.images.fallback.src}`,
    twitterDescription: description || site.siteMetadata.description,
    pinterestDomainVerify: site.siteMetadata.pinterestDomainVerify,
    googleSearchConsole: site.siteMetadata.googleSearchConsole,
    bingWebMaster: site.siteMetadata.bingWebMaster
  }

  {/* Check if there are pages with an alternative language */}
  const hasAlterLangPage = activeDocMeta.alternateLanguages
  const href = {
    en: location.pathname.includes('/es') ? location.pathname.replace('/es','') : location.pathname,
    es: location.pathname.includes('/es') ?  location.pathname : location.pathname.replace('/','/es/')
  }

  const noIndexPages = ['thank-you', '404', 'error', 'wyng-test-form', 'globalvariables']

  const checkNoIndexPage = (pathname, pages) => {
    return pages.some(page => pathname?.includes(page))
  }

  const isNoIndexPage = location.href?.includes('dev.') || location.href?.includes('staging.') || checkNoIndexPage(location.pathname, noIndexPages)

  React.useEffect(() => {
    setTimeout(() => {
      const elements = document.querySelectorAll('[data-react-helmet="true"]');
      elements.forEach(element => {
        element.removeAttribute('data-react-helmet');
      });
    }, 100);
  }, []);
  return (
      <Helmet>
        {/* page lang tag to html */}
        <html lang={activeDocMeta?.lang === LANG.EN ?  "en-US" : "es-US" } />

      {/* meta title and description */}
        <title>{ seo.title }</title>
        <meta name="description" content={seo.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        {/* Referrer Policies */}
        <meta name="referrer" content="strict-origin-when-cross-origin"/>

        {/* robots noindex, nofollow */}
        {isNoIndexPage && <meta name="robots" content="noindex, nofollow"/>}

        {/* Hreflang tags */}
        <link rel="canonical" href={`${seo.siteUrl}${modifiedPathname}`} />

        {currentPage && HREFLANG_ALT[currentPage] && HREFLANG_ALT[currentPage]?.map((item, index) => {
          // eslint-disable-next-line array-callback-return
          if (lang === item) return
            return (<link key={index} rel="alternate" hreflang={item.lang} href={item.href}  />)
          })
        }
        { /*Open Graph*/ }
        <meta property="og:title" content={seo.ogTitle} />
        <meta property="og:description" content={seo.ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${seo.siteUrl}${modifiedPathname}`} />

        {seo.ogImage && <meta property="og:image" content={seo.ogImage} />}
        {seo.ogImage && <meta property="og:image:secure_url" content={seo.ogImage} />}
        {seo.ogImage && <meta property="og:image:width" content={image  ? "1200" : seo.ogImage.width} />}
        {seo.ogImage && <meta property="og:image:height" content={image  ? "630" : seo.ogImage.height} />}


        { /*Twitter */ }
        <meta name="twitter:card" content={seo.twitterCard} />
        <meta name="twitter:site" content={seo.twitterSite} />
        <meta name="twitter:title" content={seo.twitterTitle} />
        <meta name="twitter:description" content={seo.twitterDescription} />
        <meta name="twitter:image" content={seo.twitterImage} />

        { /*Pinterest*/ }
        <meta name="p:domain_verify" content={seo.pinterestDomainVerify} />

        { /*Google Search Console */ }
        <meta name="google-site-verification" content={seo.googleSearchConsole} />

        { /*Bing WebMaster */ }
        <meta name="msvalidate.01" content={seo.bingWebMaster} />

        { children }
      </Helmet>
  )
}