import React from 'react'
import { useLocation } from "@reach/router"
import { PrismicRichText } from "@prismicio/react"

//assets
import useWindowSize from "../../hooks/useWindowSize"
import CustomLink from "../../components/CustomLink"
import { setLearnMoreClickedEvent } from "../../assets/js/data-layer"
import { useConsent } from "../../components/ConsentContext"



export const AdPanel = ({ slice }) => {
  const consentAccepted = useConsent()
  const location = useLocation()
  const { width } = useWindowSize()

  const titleRows = slice.primary.title.richText


  const button = {
    url: slice.primary.button_link.url || '',
    text: slice.primary.button_text.text || '',
  }

  const bg = {
    imageMobile: slice.primary.background_image?.thumbnails?.mobile?.url || '',
    imageScreen: slice.primary.background_image?.url || ''
  }

  const handleOnClick = () => {
    const dl = {
      url: button.url,
      referrer: location.pathname,
      name: button.text.toLowerCase()
    }
    setLearnMoreClickedEvent(dl, consentAccepted)
  }

  return (
      <section className='ad-panel'
               style={{ backgroundImage: `${width < 768 
                     ? `linear-gradient(to top, #000 26.56%, rgba(0, 0, 0, 0.00) 100%), url(${bg.imageMobile})` 
                     : `linear-gradient(to right, #000 10%, rgba(0, 0, 0, 0.00) 100%), url(${bg.imageScreen})`}`}}
      >
        <div className='ad-panel__content'>
          <h2 className='ad-panel__title'>
            {titleRows.length !== 0 && titleRows.map((row) => {
              return  <span key={row.text}><PrismicRichText field={[row]}/><br/></span>
            })}
          </h2>


          <CustomLink
              to={button.url}
              title={button.text}
              className="button-white ad-panel__button"
              onClick={ handleOnClick }
          >
            {button.text}
          </CustomLink>
        </div>
      </section>
  )
}