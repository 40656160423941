const PRODUCT_EARNS = {
    "0810113770000": {"cat": "vodka"},
    "0810113770017": {"cat": "vodka"},
    "0810113770031": {"cat": "vodka"},
    "0810113770024": {"cat": "vodka"},
    "0810113770086": {"cat": "vodka"},
    "0810113770093": {"cat": "vodka"},
    "0810113770116": {"cat": "vodka"},
    "0810113770109": {"cat": "vodka"},
    "0810113770055": {"cat": "vodka"},
    "0810113770048": {"cat": "vodka"},
    "0810113770062": {"cat": "vodka"},
    "0810113770079": {"cat": "vodka"},
    "0635985801962": {"cat": "vodka+soda"},
    "0635985801955": {"cat": "vodka+soda"},
    "0635985801931": {"cat": "vodka+soda"},
    "0635985801948": {"cat": "vodka+soda"},
    "0635985801979": {"cat": "vodka+soda"},
    "0635985548904": {"cat": "original"},
    "0635985800064": {"cat": "original"},
    "0635985800262": {"cat": "original"},
    "0635985100249": {"cat": "original"},
    "0635985801085": {"cat": "surf hard seltzer"},
    "0635985800804": {"cat": "surge hard seltzer"},
    "0635985801344": {"cat": "refrshr lemonade hard seltzer"},
    "0635985800293": {"cat": "refrshr iced tea"},
    "0635985200253": {"cat": "original"},
    "0635985260899": {"cat": "original"},
    "0635985260875": {"cat": "original"},
    "0635985100331": {"cat": "original"},
    "0635985800347": {"cat": "original"},
    "0635985800361": {"cat": "original"},
    "0635985025986": {"cat": "original"},
    "0635985260660": {"cat": "original"},
    "0635985200284": {"cat": "original"},
    "0635985801573": {"cat": "original"},
    "0635985801610": {"cat": "original"},
    "0635985100423": {"cat": "original"},
    "0635985100478": {"cat": "original"},
    "0635985100492": {"cat": "original"},
    "0635985800903": {"cat": "original"},
    "0635985801986": {"cat": "original"},
    "0635985801993": {"cat": "original"},
    "0635985802051": {"cat": "original"},
    "0635985802136": {"cat": "original"},
    "0635985802143": {"cat": "original"},
    "0635985802150": {"cat": "original"},
    "0635985000044": {"cat": "surge hard seltzer"},
    "0635985000020": {"cat": "surge hard seltzer"},
    "0635985800712": {"cat": "surge hard seltzer"},
    "0635985800736": {"cat": "surge hard seltzer"},
    "0635985802099": {"cat": "surge hard seltzer"},
    "0635985802013": {"cat": "surge hard seltzer"},
    "0635985802075": {"cat": "surge hard seltzer"},
    "0635985802082": {"cat": "surge hard seltzer"}
}

export { PRODUCT_EARNS }