import * as React from 'react'

//assets
import useWindowSize from "../../hooks/useWindowSize";


export const BasicBanner = ({ slice }) => {
  const { width } = useWindowSize()

  const bg = {
    imageMobile: slice.primary.banner?.thumbnails.mobile?.url || '',
    imageScreen: slice.primary.banner?.url || ''
  }

  return (
      <section className="basic-banner" style={{backgroundImage: `${width <= 768 ? `url(${bg.imageMobile})` : `url(${bg.imageScreen})`}`}}></section>
  )
}