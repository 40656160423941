// core
import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

// components
import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import SurveyPage from "../components/SurveyPage"
import CommonPageBanner from '../components/CommonPageBanner'

const SurveyEmbededPage = ({ data }) => {

    const pageContent = data.prismicSurveyPage
    const pageData = data.prismicSurveyPage.data

    const pageUrl = data.prismicSurveyPage.uid
    const { lang, type, url } = pageContent
    const { meta_title, meta_description, social_card, wyng_id, wyng_token } = pageData

    const alternateLanguages = pageContent.alternate_languages || []
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    return(
        <Layout currentPage={pageUrl} activeDocMeta={activeDoc} noSignUpPopup={true}>
             <Seo
                title={meta_title?.text}
                description={meta_description?.text}
                social_card={social_card?.url}
            />
            <section className='inside-content'>
                {(pageData.banner_background.url) && <CommonPageBanner
                    backgroundUrl={pageData.banner_background.url}
                />}
                <SurveyPage
                    id={wyng_id}
                    token={wyng_token}
                />
            </section>
        </Layout>
    )
}

export const query = graphql`
query SurveyPageQuery ($uid: String, $id: String, $lang: String) {
    prismicSurveyPage (uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        banner_background {
          alt
          url
        }
        wyng_id{
          text
        }
        wyng_token{
          text
        }
        meta_description {
          richText
          text
          html
        }
        meta_title {
          html
          richText
          text
        }
        social_card {
          url
          alt
        }
      }
      uid
      type
      lang
      id
      url
    }
}`

export default withPrismicPreview(SurveyEmbededPage)