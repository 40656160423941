// core
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { SliceZone } from '@prismicio/react'

// preview
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

// components
import { components } from '../slices/product-locator'

const ProductLocator = ({ activeDocMeta }) => {
  const { lang } = activeDocMeta
  // Can't use string interpolation for a loop
  const queryData = useStaticQuery(graphql`
    {
      EN: prismicProductLocator(lang: { eq: "en-us" }) {
        data {
          body {
            ... on PrismicProductLocatorDataBodyProductLocatorModule {
              id
              slice_label
              slice_type
              primary {
                locator_title {
                  html
                  text
                }
                locator_button_text
                locator_button_link {
                  url
                }
                locator_description {
                  html
                  text
                }
              }
              items {
                locator_type_icon {
                  url
                  alt
                }
                locator_type_text
              }
            }
          }
        }
      }
      ES: prismicProductLocator(lang: { eq: "es-mx" }) {
        data {
          body {
            ... on PrismicProductLocatorDataBodyProductLocatorModule {
              id
              slice_label
              slice_type
              primary {
                locator_title {
                  html
                  text
                }
                locator_button_link {
                  url
                }
                locator_button_text
                locator_description {
                  html
                  text
                }
              }
              items {
                locator_type_icon {
                  url
                  alt
                }
                locator_type_text
              }
            }
          }
        }
      }
    }
  `);
  const { data } = useMergePrismicPreviewData(queryData)
  const pageData = lang === 'en-us' ?  data.EN.data :  data.ES.data
  const sliceData = pageData.body

  return <SliceZone slices={sliceData} components={components} />;
}

export default ProductLocator