// core
import React from 'react'
import { graphql } from "gatsby";
import { ProductTastingNoteItem } from "./productTastingNoteItem";
import {AnchorLink} from "../../components/AnchorLink";


export const ProductTastingNotes = ({ slice }) => {
  // console.log('SplitBanner', slice)
  const contentReverse = slice.primary.reverse_content ? 'reverse': ''
  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  return (<section className="tasting-notes" id={`tasting-notes-${sectionId}`}>
    { anchorLink && <AnchorLink link={anchorLink}/> }

    <div className={`tasting-notes__container ${contentReverse}`}>
      {slice.items.map((item, index) => {
        return <ProductTastingNoteItem item={item} key={index} index={index} reverse={contentReverse}/>
      })}
      </div>
  </section>);

}

export const fragment = graphql`
  fragment PageDataBodyProductTastingNotes on PrismicGenericPageDataBodyProductTastingNotes {
    id
    primary {
      reverse_content
      section_id{
        text
      }
      anchor_link{
        text
      }
    }
    items {
      banner {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      cta_button_link {
        url
      }
      github_video_filename_mobile
      github_video_filename_desktop
      content_type
      show_cta_button
      show_title
      show_subtitle
      show_paragraph
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      subtitle_aroma {
        text
        richText
      }
      subtitle_taste {
        text
        richText
      }
      subtitle_finish {
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      product_type
      product_image {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
      paragraph_aroma {
        text
        richText
      }
      paragraph_taste {
        text
        richText
      }
      paragraph_finish {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
    }
  }`