import { NutritionTable } from './NutritionTable'
import { AllFlavors } from './AllFlavors'
import { FeaturedProducts } from './FeaturedProducts'
import { InstagramGallery } from "../InstagramGallery";

export const pdpComponents = {
    nutrition_table: NutritionTable,
    all_flavors: AllFlavors,
    featured_products: FeaturedProducts,
    instagram_gallery: InstagramGallery
}