import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from "@prismicio/react";

import { getFontSize } from "../../assets/js";
import useWindowSize from "../../hooks/useWindowSize";

// constants
import { COLOR } from "../../constants/colors";
import {AnchorLink} from "../../components/AnchorLink";

export const ProductRange = ({ slice }) => {
  // console.log("ProductRange Slice", slice)
  const { width } = useWindowSize()
  const playerRef = useRef()

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showSubTitle = slice.primary.show_subtitle
  const showTitle = slice.primary.show_title
  const showParagraph = slice.primary.show_paragraph

  const productType = slice.primary.product_type
  const contentTypeImage = slice.primary.content_type

  // Text
  const subTitle = slice.primary.subtitle?.richText || []
  const subTitleTextColor =slice.primary.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = slice.primary.subtitle_font_size_desktop || '38'
  const subTitleFontSizeMobile = slice.primary.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextFontSizeDesktop = slice.primary.title_font_size_desktop || '44'
  const titleTextFontSizeMobile = slice.primary.title_font_size_mobile || '24'
  const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)

  const paragraph = slice.primary.paragraph?.richText || []
  const paragraphTextColor = slice.primary.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = slice.primary.paragraph_font_size_desktop || '24'
  const paragraphFontSizeMobile = slice.primary.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  // image
  const image = {
    src: width < 768 ? slice.primary.banner?.thumbnails?.mobile.url : slice.primary.banner?.url,
    alt: slice.primary.banner?.alt || ''
  }

  // video
  const githubVideoFilename = width < 768 ? slice.primary?.github_video_filename_mobile : slice.primary?.github_video_filename_desktop;
  const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
  const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''

  useEffect(() => {
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current?.play()
    });

  }, [])

  return (
      <section className={`assortiment ${productType}`} 
        id={`product-range-${sectionId}`}
      >
        { anchorLink && <AnchorLink link={anchorLink}/> }

        <div className='assortiment__text-wrap'>
          { showSubTitle && <h4 className='assortiment__subtitle'>
            { subTitle.map((row) => {
              return  <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>})
            }
          </h4> }

          { showTitle && <h3 className='assortiment__title'>
            { title.map((row) => {
              return  <span key={row.text} style={titleStyles}>
                <PrismicRichText field={[row]}/><br/>
              </span>})
            }
          </h3> }

          { showParagraph && <p className='assortiment__paragraph'>
            { paragraph.map((row) => {
              return  <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>})
            }
          </p>}


          <div className={`assortiment__range ${productType}`}>
            {slice.items.map((item, i)=>{
              return <img src={item.product_image.url} alt={item.product_image.alt} key={i} className={`assortiment__range-img ${productType}`}/>
            })}
          </div>


        </div>
        { contentTypeImage && <img src={image.src} alt={image.alt} className='assortiment__banner'/> }
        { !contentTypeImage && <video className='assortiment__video' playsInline autoPlay muted loop width='100%' height='100%' ref={playerRef} src={video}/> }
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyProductRange on PrismicGenericPageDataBodyProductRange  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      banner {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      github_video_filename_mobile
      github_video_filename_desktop
      content_type
      product_type
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
      show_paragraph
      show_title
      show_subtitle
      subtitle{
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
    }
    items {
      product_image {
        url
        alt
      }
    }
  }
`;