import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { PrismicProvider } from '@prismicio/react'
import { ParallaxProvider } from 'react-scroll-parallax';
import { ConsentProvider } from './src/components/ConsentContext';
import { PreloaderProvider } from './src/components/PreloaderContext';

import ConsentCookies from "./src/components/ConsentCookies";

import { htmlSerializer } from './src/utils/htmlSerializer'
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; /* eslint-disable import/first */

import 'bootstrap/dist/css/bootstrap.min.css'
import './src/assets/css/styles.scss'

export const wrapRootElement = ({ element }) => (
<PreloaderProvider>
  <ConsentProvider>
      <ParallaxProvider>
          <PrismicProvider
            linkResolver={linkResolver}
            internalLinkComponent={({ href, ...props }) => (
              <Link to={href} {...props} />
              )}
              richTextComponents={htmlSerializer}
              >
            <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
                {element}
              <ConsentCookies/>
            </PrismicPreviewProvider>
          </PrismicProvider>
      </ParallaxProvider>
  </ConsentProvider>
</PreloaderProvider>
)

// ES5 way
// exports.onRouteUpdate = () => {
// ES6 way
// export const onRouteUpdate = () => {
//   window.analytics && window.analytics.page();
// };


function initGTM() {
  if (window.isGTMLoaded) {
    return false;
  }

  window.isGTMLoaded = true;

  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_TRACKING_ID}`;

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', `${process.env.GATSBY_GA_TRACKING_ID}`);
  };

  document.head.appendChild(script);
}

function loadGTM(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
}

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }

  document.onreadystatechange = () => {
    if (document.readyState !== 'loading') {
      setTimeout(initGTM, 3500);
    }
  };

  document.addEventListener('scroll', loadGTM);
  document.addEventListener('mousemove', loadGTM);
  document.addEventListener('touchstart', loadGTM);

}
