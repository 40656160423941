import React, { useRef, useEffect,useState } from 'react'
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

//assets
import useWindowSize from "../../hooks/useWindowSize";
import { getFontSize } from "../../assets/js";
import useVisibility from '../../utils/visibilityHook';

// components
import { ContentPanelCard } from "./contentPanelCard/ContentPanelCard";
import { AnchorLink } from "../../components/AnchorLink";

// constants
import { COLOR } from "../../constants/colors";


export const ContentPanel = ({ slice }) => {
  // console.log("ContentPanel", slice)

  const { width } = useWindowSize()
  const cards = slice.items || []
  const isMobile = width <= 768;
  const componentRef = useRef(null);
  const isVisible = useVisibility(componentRef);

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showTitle = slice.primary.show_title
  const showSubTitle = slice.primary.show_subtitle
  const allowTransition = slice.primary.allow_transition

  // Text
  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextSizeDesktop = slice.primary.title_font_size_desktop || '58'
  const titleTextSizeMobile = slice.primary.title_font_size_mobile || '30'
  const titleTextFontSize = getFontSize(width, titleTextSizeDesktop, titleTextSizeMobile)

  const subTitle = slice.primary.subtitle?.richText || []
  const subTitleTextColor =slice.primary?.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = slice.primary?.subtitle_font_size_desktop || '24'
  const subTitleFontSizeMobile = slice.primary?.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

  const sectionSpacingTop = slice.primary?.section_spacing_top || '0'
  const sectionSpacingBottom = slice.primary?.section_spacing_bottom || '0'
  const sectionBackgroundColor = slice.primary?.section_background_color || COLOR.WHITE

  // styles
  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }

  const sectionStyles = {
    marginTop:`${sectionSpacingTop}px`,
    marginBottom:`${sectionSpacingBottom}px`,
    backgroundColor:`${sectionBackgroundColor}`
  }

  useEffect(() => {
    if(isVisible == true && allowTransition){
      applyFadeInEffect();
    }
    if(isVisible == false && allowTransition){
      removeFadeInEffect();
    }
  },[isVisible])
  const removeFadeInEffect = () => {
    const items = document.querySelectorAll('.content-panel__card');
    items.forEach((item, index) => {
      setTimeout(() => {
        if(item.classList.contains('fade-in')){
          item.classList.remove('fade-in');
        }   
      }, index * 1000);
  });
  };
  const applyFadeInEffect = () => {
    const items = document.querySelectorAll('.content-panel__card');
    items.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('fade-in');
          
      }, index * 1000); // Each box fades in one second after the previous one
  });
  };
  return (
      <section ref= {componentRef} className='content-panel' id={`content-panel-${sectionId}`} style={sectionStyles}>
        { anchorLink && <AnchorLink link={anchorLink}/> }

        <div className='content-panel__content'>

  {showTitle && <h3 className={`content-panel__title ${allowTransition?'fade-in-animation-enable':''} ${isVisible && allowTransition ? 'fade-in-bottom-up':''}`}>
    {title.map((row, index) => {
      return <span key={`${row.text}-${index}`} style={titleStyles}>
      <PrismicRichText field={[row]}/><br />
    </span>
    })
    }
  </h3>}

  { showSubTitle && <h4 className='content-panel__subtitle_main'>
    { subTitle.map((row, index) => {
      return  <span key={index} style={subTitleStyles}>
      <PrismicRichText field={[row]}/><br/>
    </span>})
    }
  </h4> }

  <div className={`row content-panel__cards`}>
    {cards.length !== 0 && cards.map((card, index) => {
       return <ContentPanelCard card={card} ind={index} key={index} allowTransition = {allowTransition} isMobile = {isMobile} isVisible = {isVisible} />
    })}
  </div>
</div>
      
       
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyContentPanel on PrismicGenericPageDataBodyContentPanel  {
    id
    primary{
      section_id{
        text
      }
      anchor_link{
        text
      }
      show_title
      show_subtitle
      title {
        text
        richText
      }
      allow_transition
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      subtitle{
       text
       richText
     }
     subtitle_text_color
     subtitle_font_size_desktop
     subtitle_font_size_mobile
     section_spacing_top
     section_spacing_bottom
     section_background_color
    }
    items {
      youtube_video
      youtube_link
      banner {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      cta_button_link {
        url
      }
      show_cta_button
      show_subtitle
      show_paragraph
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      subtitle {
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
    }
  }`