import React from 'react'
import { useLocation } from "@reach/router";
import { PrismicRichText } from "@prismicio/react";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'

import {
  faXTwitter,
  faFacebook
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { copyLink } from "../../assets/images/icons";

export const EventDetails = ({ slice }) => {
  const location = useLocation()
  const titleRows = slice.primary.title?.richText || []
  const eventLocation = slice.primary.location.text || ''
  const date = slice.primary.date.text || ''

  const showCtaBlock = slice.primary.show_cta_block
  const label = slice.primary.closed_date?.text || ''

  const ctaButton = {
    url: slice.primary.cta_button_link?.url || '',
    text: slice.primary.cta_button_text?.text || ''
  }

  const learnMoreButton = {
    url: slice.primary.learn_more_button_link?.url || '',
    text: slice.primary.learn_more_button_text?.text || ''
  }

  const sideImage = {
    url: slice.primary.image?.url || '',
    alt: slice.primary.image?.alt || ''
  }


  const socialLinks = {
    facebook : slice.primary.facebook_link?.url || location.href,
    twitter: slice.primary.twitter_link?.url || location.href,
    copyLink: slice.primary.copy_link?.url || location.href,
  }

  const handlerCopyOnClick = () => {
    const el = document.createElement("input");
    el.value = socialLinks.copyLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }



  return (
      <section className="event-details">
        <div className="event-details__wrap">
          {titleRows.length !== 0 && <h2 className='event-details__title'>
            {titleRows.map((row) => {
              return <span key={row.text}><PrismicRichText field={[row]}/><br/></span>
            })}
          </h2> }

          <div className="event-details__dtls">
            <div className="event-details__dtls-item event-details__location">
              <h3>Location</h3>
              <p>{ eventLocation }</p>
            </div>
            <div className="event-details__delimiter"></div>
            <div className="event-details__dtls-item event-details__date">
              <h3>Date</h3>
              <p>{date}</p>
            </div>
          </div>
        </div>

        {showCtaBlock  && <div className='event-details__cta'>
            <div className='event-details__cta-block'>
            <div className='event-details__cta-block-btns-wrap'>
              <div className='w-100 event-details__btn-wrap'>
                <a href={ ctaButton.url } className="button-white event-details__action-button">{ ctaButton.text }</a>
                <p className="event-details__label">{label}</p>
              </div>
              <div className='w-100 event-details__btn-wrap'>
                <a href={ learnMoreButton.url } className="button-black event-details__action-button event-details__action-button--bottom">{ learnMoreButton.text }</a>
              </div>
            </div>
            <img className="event-details__image" src={sideImage.url} alt={sideImage.alt}/>
          </div>
          <div className='event-details__socials'>
            <ul className="event-details__socials-list">
              <li>
                <a href={`https://www.facebook.com/sharer.php?u=${encodeURI(socialLinks.facebook)}`}>
                  <FontAwesomeIcon icon={faFacebook} className="event-details__socials-fa-icons"/>
                </a>
              </li>
              <li>
                <a href={`https://twitter.com/share?url=${encodeURI(socialLinks.twitter)}`}>
                  <FontAwesomeIcon icon={faXTwitter} className="event-details__socials-fa-icons"/>
                </a>
              </li>
              <li className="event-details__socials-list-item--copy">
                <button data-tooltip-id="copied"
                        className='event-details__socials-fa-icons event-details__socials-fa-icons--copy'
                        onClick={handlerCopyOnClick}
                >
                  <img src={copyLink} alt="Copy Link Icon" />
                </button>
                <Tooltip id="copied" place='right' openOnClick content="Copied"/>
              </li>
            </ul>
          </div>
        </div> }
      </section>
  )
}