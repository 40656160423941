import React from 'react'
import { graphql } from 'gatsby'

// assets
import useWindowSize from '../../hooks/useWindowSize';
import { OvalImageItemMobile, OvalImageItemDesktop } from './ovalImageItems';

export const OvalImage = ({ slice }) => {
  // console.log("OvalImage Slice", slice)
  const { width } = useWindowSize()

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  if(width > 991) {
    // Desktop layout
    return slice.items.map((item, index) => {
      return <OvalImageItemDesktop item={item} index={index} key={index} itemId={sectionId} anchorLink={anchorLink} />
    })
  } else {
    // Mobile layout with swiper
    return (
      <OvalImageItemMobile slice={slice} itemId={sectionId} anchorLink={anchorLink}/>
    );
  }
}

export const fragment = graphql`
  fragment PageDataBodyOvalImage on PrismicGenericPageDataBodyOvalImage  {
    id
    primary{
      section_id{
        text
      }
      anchor_link{
        text
      }
    }
    items {
      image {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
      content_reverse
      show_cta_button
      cta_button_bg_color
      cta_button_bg_color_transparency
      cta_button_bg_color_hover
      cta_button_bg_color_hover_transparency
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_button_link {
        url
      }
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      show_paragraph
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
      show_subtitle
      subtitle{
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      show_title
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      section_bg_color
    }
  }
`;