  // Data-snippet for Organisation to the homepage
  const homepageSnippet = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "White Claw Seltzer Co.",  
    "url": "https://www.whiteclaw.com",
    "description": "White Claw® is dedicated to making the best-tasting alcoholic drinks in the world. Try our iconic Hard Seltzer and our Triple Wave Filtered ™ Vodka.",
    "logo": "https://www.whiteclaw.com/static/whiteclaw-logo-blk-120x120-6858d6a9163751e71623ca190f394bcc.png",  
    "brand": {"@type": "Brand", "name": "White Claw"}, "slogan": " Grab Life By The Claw™",
    "sameAs": [ "https://www.facebook.com/whiteclaw", "https://twitter.com/whiteclaw", "https://www.instagram.com/whiteclaw", "https://en.wikipedia.org/wiki/White_Claw_Hard_Seltzer" ] 
    }

  const ourStorySnippet = {
        "@context": "https://schema.org",
        "@type": "AboutPage",
        "mainEntity": {
          "@type": "Organization",
          "name": "White Claw Seltzer Works",
          "url": "https://www.whiteclaw.com/",
          "logo": "https://www.whiteclaw.com/static/whiteclaw-logo-blk-120x120-6858d6a9163751e71623ca190f394bcc.png", // Devs to update if more appropriate / higher resolution logo
          "sameAs": [
            "https://www.facebook.com/whiteclaw",
            "https://www.instagram.com/whiteclaw/",
            "https://x.com/whiteclaw"
          ],
          "description": "Hard Seltzer & Triple Wave Filtered™ Vodka. Enjoy Responsibly. 21+ only. WHITE CLAW, CHI, IL",
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Customer Service",
            "email": "customersupport@whiteclaw.com",
          },
          "knowsAbout": [
            "Hard Seltzer Beverages",
            "Flavored Seltzer",
            "Vodka",
            "Low Calorie Drinks",
            "Gluten-Free Alcohol",
            "Beach Beverages",
            "Summer Drinks"
          ]
        }
      }
export { homepageSnippet ,ourStorySnippet}