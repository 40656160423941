export const downloadMedia = (media, fileName) => {
  const url = media?.includes('?') ? media.split('?')[0] : media

  // Fetch the media file as a blob
  fetch(url)
      .then(response => response.blob())
      .then(blob => {

        // Create a temporary URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = blobUrl;
        anchor.download = fileName || url.substring(url.lastIndexOf('/') + 1);
        anchor.click();

        // Clean up by revoking the temporary URL
        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => console.error('Error downloading media:', error));
}