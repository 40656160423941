import React, { useEffect, useState, useCallback } from 'react';

const PreloaderContext = React.createContext({
  isPreloaderActive: true,
  setIsPreloaderActive: () => {},
  isCustomLoading: false,
  setIsCustomLoading: () => {},
});

export const PreloaderProvider = ({ children }) => {
  const [isPreloaderActive, setIsPreloaderActive] = useState(true);
  const [isCustomLoading, setIsCustomLoading] = useState(false);

  const handleClassChange = () => {
    let body = document.querySelector("body");
    body.classList.add("preloader_ready");
    setTimeout(function () {
      body.classList.remove("preloader_active");
      body.classList.remove("preloader_ready");
      body.classList.add("page_ready");
    }, 500);
  }

  const handleReadyStateChange = useCallback(() => {
    try {
      if ((document.readyState === "complete") && !isCustomLoading) {
        setIsPreloaderActive(false);
        handleClassChange();
      } else {
        // If the conditions aren't met, set a timeout to hide the preloader after a certain amount of time
        setTimeout(handleClassChange, 3000);
      }
    } catch (error) {
      console.error('Error in handleReadyStateChange:', error);
    }
  }, [isCustomLoading]);

  useEffect(() => {
    document.addEventListener('readystatechange', handleReadyStateChange);

    // Call the function manually to ensure it's run at least once
    handleReadyStateChange();

    return () => {
      document.removeEventListener('readystatechange', handleReadyStateChange);
    };
  }, [handleReadyStateChange]);

  return (
    <PreloaderContext.Provider value={{ isPreloaderActive, setIsPreloaderActive, isCustomLoading, setIsCustomLoading }}>
      {children}
    </PreloaderContext.Provider>
  );
};

export default PreloaderContext;