import React, { useState } from "react";

//assets
import useWindowSize from "../../../hooks/useWindowSize";
import { getButtonCustomStyles, getFontSize } from "../../../assets/js";
import * as images from "../../../assets/images/icons";

//components
import CustomLink from "../../../components/CustomLink";
import YouTubeModal from "../../../components/YouTubeModal";

//constants
import { COLOR } from "../../../constants/colors";

export const MembershipListItem = ({card, index}) => {
  const { width } = useWindowSize()

  // console.log(card)

  const imageSrc = card.title_icon.url || ''
  const imageAlt = card.title_icon?.alt || ''

  const paragraph = card.paragraph?.richText || []
  const paragraphTextColor = card.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = card.paragraph_font_size_desktop || '20'
  const paragraphFontSizeMobile = card.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  return <div key={`membership-card-${index}`} className='membership__card'>
    <div className='position-relative membership__image-wrap'>
      <img src={imageSrc} alt={imageAlt} className='membership__image' />
    </div>
    <div className='membership__content-wrap'>
      <p className='membership__paragraph'>
        { paragraph.map((row, index) => {
          return  <span key={index} style={paragraphStyles}>{row.text}<br/></span>})
        }
      </p>
    </div>
  </div>
}