import * as React from 'react'
import postcss from 'postcss';

export const PageCustomStyle = ({ cssString }) => {
    try{
        postcss.parse(cssString)
        return (
            <style>{cssString}</style>
        )
    }catch(error){
        console.error(error)
        return
    }
}