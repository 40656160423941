import { dataTrack } from "./functions"


// shoreclub_form_start
const setShoreClubFormEvent = (ota)  => {

  setTimeout(()=> {
    dataTrack('shoreclub_form_start', {
      'page_location': 'hero section',
    }, 'track', ota)
  }, 350)
}

// learn_more_clicked
const setLearnMoreClickedEvent = (data, ota)  => {

  const { url, referrer, name, page_name } = data

  // wait OneTrustCookie
  setTimeout(()=> {
    dataTrack('learn_more_clicked', {
      'click_url': url,
      'button_name': name,
      'referrer': referrer,
      'page_name':page_name ? page_name : ''
    }, 'track', ota)
  }, 350)
}

// contact_us_form_submitted
const setContactUsFormSubmittedEvent = (data, ota)  => {

  const { topicId, topicName, batchn, sop, pt, pq, contact_us_flavor_name } = data

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'contact_us_form_submitted',
    'topic_id': topicId,
    'topic_name': topicName,
    'dtls': {
      'cus': {
        'batchn': batchn,
        'sop': sop,
        'pt': pt,
        'pq': pq,
        'contact_us_flavor_name': contact_us_flavor_name
      }
    }
  })
  if(ota) {
    window.analytics.track("Contact Us Form Submitted", {
      'topic_id': topicId,
      'topic_name': topicName,
      'batch_number': batchn,
      'state_of_purchase': sop,
      'package_type': pt,
      'package_quantity': pq,
      'contact_us_flavor_name': contact_us_flavor_name
    })
  }
}

// nutrition_ingredients_clicked
const setNutritionIngClickedEvent = (product, ota)  => {
  dataTrack('nutrition_ingredients_clicked', {
    'product_flavor': product.name,
    'product_category': product.category
  }, 'track', ota)
}

// email_subscription_signup
const setSubscriptionSignUpEvent = (location, ota)  => {
  dataTrack('email_subscription_signup', {
    'page_location': location,
  }, 'track', ota)
}

// email_popup_impression
const setSubscriptionPopUpShowEvent = (ota) =>{
  dataTrack('email_popup_impression', undefined, ota)
}


// category_viewed
const setCategoryViewedEvent = (data, ota) =>{

  const {category, tab } = data

  dataTrack('category_viewed', {
    'category': category,
    'view_by_section': tab
  }, 'track', ota)
}


// buy_now_clicked
const setBuyNowClickedEvent = (data, ota) =>{

  const { flavor, category } = data

  dataTrack('buy_now_clicked', {
    'product_flavor': flavor,
    'product_category': category
  }, 'track', ota)
}

// social_icon_clicked
const setSocialIconClickedEvent = (data, ota) =>{

  const { platform, location } = data

  dataTrack('social_icon_clicked', {
    'social_platform': platform,
    'page_location': location
  }, 'track', ota)
}


// email_unsubscribe_confirmed
const setUnsubscribeConfirmedEvent = (ota) =>{
  dataTrack('email_unsubscribe_confirmed', undefined, 'track', ota)
}

// social_post_clicked
const setSocialPostClickedEvent = (data, ota) =>{

  const { position } = data

  dataTrack('social_post_clicked', {
    'tile_position': position
  }, 'track', ota)
}


// event_sign_up_clicked
const setEventSignUpClickedEvent = (data, ota) =>{

  const {url, name, location} = data

  dataTrack('event_sign_up_clicked', {
    'click_url': url,
    'event_name': name,
    'event_location': location
  }, 'track', ota)
}


// event_viewed_clicked
const setEventViewedEvent = (data, ota) =>{

  const {url, name, location, status} = data

  dataTrack('event_viewed_clicked', {
    'click_url': url,
    'event_name': name,
    'event_location': location,
    'event_status': status
  }, 'track', ota)
}

// product_flavor_viewed
const setProductFlavorViewedEvent = (data, ota) =>{

  const {flavor, category, size} = data

  dataTrack('product_viewed', {
    'page_category': 'PDP',
    'product_flavor': flavor,
    'product_category': category,
    'product_size': size
  }, 'track', ota)
}

// product_flavor_clicked
const setProductFlavorClickedEvent = (data, ota) =>{

  const {flavor, category, position, url, location, size} = data

  // wait OneTrustCookie
  setTimeout(()=>{
    dataTrack('product_flavor_clicked', {
      'product_flavor': flavor,
      'product_category': category,
      'product_position': position,
      'page_url': url,
      'page_location': location,
      'product_size': size
    }, 'track', ota)
  }, 350)
}

// global lang and country
const setGlobalLangAndCountryEvent = (lang, ota) => {
  dataTrack(undefined, {
    'country': 'US',
    'language': lang
  }, 'page', ota)
}

// view_by_selection
const setViewByRangeEvent = (data, ota) => {
  dataTrack('view_by_selection', {
    'view_by_selection': data
  }, 'track', ota)
}

// play_video_clicked
const setPlayVideoEvent = (data, ota) =>{

  const {pageURL, pageLocation} = data

  dataTrack('play_video_clicked', {
    'page_url': pageURL,
    'page_location': pageLocation,
  }, 'track', ota)
}

// more_products_viewed
const setMoreProductsViewedEvent = (arrow, ota) =>{

  dataTrack('more_products_viewed', {
    'arrow': arrow
  }, 'track', ota)
}
// locator pageview event
const setLocatorPageViewEvent = (data, ota) => {
  const { language, title, url, path, referrer } = data

  dataTrack('locator_pageview', {
    'page_title': title,
    'page_url': url,
    'page_path': path,
    'referrer': referrer,
    'language': language,
    'country': "US"
  }, 'track', ota)
}

// retailer selected event - MikMak
const setRetailerSelectedEvent = (data, ota) => {
  const { supplier, flavor, category, size, price, method } = data

  dataTrack('locator_retailer_selected', {
    'supplier_name': supplier,
    'product_flavor': flavor,
    'product_category': category,
    'product_size': size,
    'product_price': price,
    'search_method': method
  }, ' track', ota)
}

// locator address searched event - MikMak
const setLocatorAddressSearchedEvent = (data, ota) => {
  const { zc, cty, st, ctry, addr } = data
  const dtls = {
    'cus': {
      'addr': addr? addr.toLowerCase() : '',
      'cty': cty? cty.toLowerCase() : '',
      'st': st? st.toLowerCase() : '',
      'ctry': ctry? ctry : '',
      'zc': zc.toLowerCase()
    }
  }

  dataTrack('locator_address_searched', {
    'dtls': dtls
  }, 'track', ota)
}

// locator flavor selected event
const setLocatorFlavorSelectedEvent = (data, ota) => {
  const {flavor, category, size, method } = data

  dataTrack('locator_flavor_selected', {
    'product_flavor': flavor,
    'product_category': category,
    'product_size': size,
    'search_method': method
  }, ' track', ota)
}

// more_pages_viewed
const setMoreThanFivePages = (ota) =>{

  dataTrack('more_pages_viewed', undefined, 'track', ota)
}
const setLearnMoreForOurStory = (data, ota)  => {

  const { url } = data

  // learn_more_clicked
  setTimeout(()=> {
    dataTrack('learn_more_clicked', {
      'click_url': url?url:'',
    }, 'track', ota)
  }, 350)
}

const setGetDirectionsEvent = (data, ota) => {
  const { supplier, pageURL } = data

  dataTrack('get_directions', {
    'page_url': pageURL,
    'supplier_name': supplier
  }, 'track', ota)
}

const setDownloadClickedEvent = (data, ota) => {
  const { fileName, clickUrl, pageUrl, clickText } = data

  dataTrack('download_clicked', {
    'file_name': fileName,
    'click_url': clickUrl,
    'click_text': clickText,
    'page_url': pageUrl
  }, 'track', ota)
}

export {
  setLearnMoreClickedEvent,
  setContactUsFormSubmittedEvent,
  setNutritionIngClickedEvent,
  setSubscriptionSignUpEvent,
  setSubscriptionPopUpShowEvent,
  setCategoryViewedEvent,
  setBuyNowClickedEvent,
  setSocialPostClickedEvent,
  setSocialIconClickedEvent,
  setProductFlavorViewedEvent,
  setProductFlavorClickedEvent,
  setGlobalLangAndCountryEvent,
  setEventSignUpClickedEvent,
  setEventViewedEvent,
  setUnsubscribeConfirmedEvent,
  setViewByRangeEvent,
  setLocatorPageViewEvent,
  setRetailerSelectedEvent,
  setLocatorAddressSearchedEvent,
  setLocatorFlavorSelectedEvent,
  setPlayVideoEvent,
  setMoreProductsViewedEvent,
  setMoreThanFivePages,
  setLearnMoreForOurStory,
  setShoreClubFormEvent,
  setGetDirectionsEvent,
  setDownloadClickedEvent
}