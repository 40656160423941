// core
import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

// assets
import useWindowSize from "../../../hooks/useWindowSize";
import {downloadMedia, getButtonCustomStyles, getFontSize} from "../../../assets/js";
import { useConsent } from "../../../components/ConsentContext";

// Import Swiper React components
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// constants
import { COLOR } from "../../../constants/colors";
import {AnchorLink} from "../../../components/AnchorLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";

// data-layer
import { setDownloadClickedEvent } from "../../../assets/js/data-layer";

export const OvalImageItemMobile = ({ slice, itemId = null, anchorLink }) => {
  // console.log('OvalImageItemMobile', item)
  const location = useLocation()
  const consentAccepted = useConsent()
  const { width } = useWindowSize();

  return (
    <Swiper 
      initialSlide={0} 
      slidesPerView={1} 
      navigation={true} 
      modules={[Navigation]} 
      className="oval-image" 
      id={itemId? `oval-image-mobile-${itemId}` : 'oval-image-mobile'}
    >
      { anchorLink && <AnchorLink link={anchorLink}/> }
      {slice.items.map((item, index) => {
        const desktopBreakpoint = 992;

        // Toggles
        const showSubTitle = item.show_subtitle;
        const showTitle = item.show_title;
        const showParagraph = item.show_paragraph;
        const showCTAButton = item.show_cta_button;


        // Links
        const isDownloadLink = item.cta_download_button
        const isTargetBlank = item.cta_button_open_in_new_tab
        const target = isTargetBlank ? '_blank' : '_self'

        // Text
        const subTitle = item.subtitle?.richText || [];
        const subTitleTextColor = item.subtitle_text_color || COLOR.BLACK;
        const subTitleFontSizeDesktop = item.subtitle_font_size_desktop || "38";
        const subTitleFontSizeMobile = item.subtitle_font_size_mobile || "20";
        const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile, desktopBreakpoint);

        const title = item.title?.richText || [];
        const titleTextColor = item.title_text_color || COLOR.BLACK;
        const titleTextFontSizeDesktop = item.title_font_size_desktop || "66";
        const titleTextFontSizeMobile = item.title_font_size_mobile || "40";
        const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile, desktopBreakpoint);

        const paragraph = item.paragraph?.richText || [];
        const paragraphTextColor = item.paragraph_text_color || COLOR.BLACK;
        const paragraphFontSizeDesktop = item.paragraph_font_size_desktop || "24";
        const paragraphFontSizeMobile = item.paragraph_font_size_mobile || "20";
        const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile, desktopBreakpoint);

        const subTitleStyles = {
          color: subTitleTextColor,
          fontSize: subTitleFontSize,
        };

        const titleStyles = {
          color: titleTextColor,
          fontSize: titleTextFontSize,
        };

        const paragraphStyles = {
          color: paragraphTextColor,
          fontSize: paragraphFontSize,
        };

        // CTA Button
        const ctaButton = {
          text: item.cta_button_text?.text || "",
          url: item.cta_button_link?.url || "",
        };

        const ctaDownloadButtonFileName = item.cta_download_file_name || ''
        const ctaButtonDownloadLink = item.cta_download_button_file?.url || ''

        // CTA Styles
        const buttonColorSet = {
          color: item.cta_button_text_color,
          bg: item.cta_button_bg_color,
          bgT: item.cta_button_bg_color_transparency,
          border: item.cta_button_border_color,
          colorHover: item.cta_button_text_color_hover,
          bgHover: item.cta_button_bg_color_hover,
          bgHoverT: item.cta_button_bg_color_hover_transparency,
          borderHover: item.cta_button_border_color_hover,
          borderRadius: item.cta_button_border_radius || "0",
        };

        const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet);

        return (
          <SwiperSlide key={index}>
            <section className="oval-image__inner" id={`oval-image-id-${index}`}>
              <div className="oval-image__img-container">
                {showTitle && (
                  <h3 className="oval-image__title">
                    {title.map((row) => {
                      return (
                        <span key={row.text} style={titleStyles}>
                          <PrismicRichText field={[row]} />
                          <br />
                        </span>
                      );
                    })}
                  </h3>
                )}

                {showSubTitle && (
                  <h4 className="oval-image__subtitle">
                    {subTitle.map((row) => {
                      return (
                        <span key={row.text} style={subTitleStyles}>
                          {row.text}
                          <br />
                        </span>
                      );
                    })}
                  </h4>
                )}

                {showCTAButton && <>
                  {!isDownloadLink && <a href={ctaButton.url} title={ctaButton.text} className="oval-image__cta-button"
                                         style={ctaButtonStyle} target={target}>
                    {ctaButton.text}
                    {isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
                  </a>}

                  {isDownloadLink &&
                      <button title={ctaButton.text} className="oval-image__cta-button" style={ctaButtonStyle}
                              onClick={() => {
                                downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                                // 'download_clicked' data layer event
                                setDownloadClickedEvent({
                                  fileName: ctaDownloadButtonFileName,
                                  clickUrl: ctaButtonDownloadLink,
                                  clickText: ctaButton.text,
                                  pageUrl: location.href
                                }, consentAccepted)
                              }}
                      >
                        {ctaButton.text}
                        <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                      </button>}
                </>
                }

                <img key={index} className="oval-image__img" src={item.image?.thumbnails.mobile?.url} alt={item.image?.thumbnails.mobile?.alt} />
              </div>
              <div className="oval-image__text-container">
                {showParagraph && (
                  <p className="oval-image__paragraph">
                    {paragraph.map((row) => {
                      return (
                        <span key={row.text} style={paragraphStyles}>
                          {row.text}
                          {paragraph.length > 1 ? <br /> : null}
                        </span>
                      );
                    })}
                  </p>
                )}
              </div>
            </section>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
