// core
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import axios from 'axios'
import { useLocation } from "@reach/router"
import { useCookies } from 'react-cookie'

// form validation
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

// components
import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import CommonPageBanner from "../components/CommonPageBanner"

// data-layer
import {setUnsubscribeConfirmedEvent} from "../assets/js/data-layer"
import { useConsent } from '../components/ConsentContext'

const UnsubscribePage = ({ data }) => {
  const [successView, setSuccessView] = useState(false)
  const [optOutReqResMessage, setOptOutReqResMessage] = useState("")
  const pageContent = data.prismicUnsubscribePage
  const pageData = data.prismicUnsubscribePage.data
  const consentAccepted = useConsent()
  const isBrowser = typeof window !== "undefined";
  const [cookies] = useCookies()
  const location = useLocation()
  const [ketchThankyou, setKetchThankyou] = useState(false);
  const [isKetchFlow, setIsKetchFlow] = useState(false);
  const OPTOUT_URL = process.env.GATSBY_OPTOUT_URL

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const unSubscriptionFormTitle = pageData.content_title.text
  const unSubscriptionFormCopy = pageData.content_description.text

  // Form input validation schema
  const optOutFormSchema = Yup.object().shape({
    email: Yup.string().required("Please provide an email.").email("Please provide a valid email."),
  })

  const userClickExitButton = () => {
    setKetchThankyou(true)
  }

  const findKetchTab = (tabType) => {
    const overviewInterval = setInterval(() => {
      const tab = document.getElementById(tabType)
      if (tab) {

        const buttons = tab.getElementsByTagName('button');

        for(let i = 0; i < buttons.length; i++) {
          if(buttons[i].textContent === 'Save' || buttons[i].textContent === 'Cancel') {

            buttons[i].addEventListener('click', userClickExitButton);
          }
        }
        clearInterval(overviewInterval);
      }
    }, 100);
  }

  useEffect(() => {
    /*
      Test Case DEV OG:
      Email: 12192023-22@test.ie
      MagID: 82a8353f-d37d-4b6f-a67f-907f02a8b8ee
      Test Case PROD OG:
      Email: 22012024-01@test.com
      MagID: 655a1618-a399-4d8f-b7f6-b23cf163c552
      Test Case Staging OG:
      Email: 29032024-test-01@test.ca
      MagID: 077774f5-97d3-4d8a-9366-863e3ae67af7

      Example of launching Ketch pop up window for OptOut options
      ..../unsubscribe?u=077774f5-97d3-4d8a-9366-863e3ae67af7
    */

    const urlParams = new URLSearchParams(location.search)
    const magID = urlParams.get('u')

    if(isBrowser && cookies?.adult && magID) {
      setIsKetchFlow(true)

      const intervalId = setInterval(() => {
        if (window.semaphore && magID) {

          window.semaphore.push(['showPreferences', {
            showOverviewTab: false,
            showConsentsTab: false,
            showRightsTab: false,
            showSubscriptionsTab: true,
          }])

          window.semaphore.push(['onWillShowExperience', function() {
            findKetchTab('subscriptionsTab')
          }])

          clearInterval(intervalId);
        }
      }, 100);
    }
  },[cookies?.adult])

  let isSubmitBlocked = false

  return (
      <Layout currentPage="unsubscribe" activeDocMeta={activeDoc}>
        <Seo
            title={pageData.meta_title?.text}
            description={ pageData.meta_description?.text}
            image={pageData.social_card?.url}
            activeDocMeta={activeDoc}
            currentPage={"unsubscribe"}
        />

        <section id="unsubscribe" className="inside-content unsubscribe">
          <CommonPageBanner backgroundUrl={pageData.banner_background.url} />
          <div className="container-fluid inside-content-row">
            <div className="row">
              <div className="col-md-12">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">

                      {!ketchThankyou &&
                          <div className='inline-preloader inline-preloader--active' style={!isKetchFlow ? {"display": "none"} : null}>
                            <div id="preloader">
                              <img src="/logo-blk.png" alt="logo" style={{"height": "calc(3vw + 77px)"}} />
                              <div className="dot-flashing"></div>
                            </div>
                          </div>
                      }

                      <h1>{ unSubscriptionFormTitle }</h1>
                      {!ketchThankyou && !isKetchFlow && !successView && <p>{ unSubscriptionFormCopy }</p>}
                      {ketchThankyou && <p>Thank you. If you submitted a request to unsubscribe, we will begin processing.</p>}

                      {!isKetchFlow &&

                        <>
                        {/* Formik Form */}
                        <Formik
                          initialValues={ // initial form values
                            {
                              email: "",
                            }
                          }
                          enableReinitialize
                          validationSchema={optOutFormSchema}

                          onSubmit={(values, { setSubmitting, resetForm }) => {

                            let data = {
                              Email: values.email.trim(),
                              "DpMktLocKeyName": "DP_MKT_WCB_US"
                            }

                            if(!isSubmitBlocked) {
                              isSubmitBlocked = true
                              axios({
                                method: "POST",
                                url: OPTOUT_URL,
                                data: JSON.stringify(data),
                                cache: false,
                                headers: {
                                  "Content-Type": "application/json; charset=utf-8"
                                }
                              }).then(function (response) {
                                isSubmitBlocked = false
                                setSuccessView(true)

                                if (response.data !== false) {
                                  setOptOutReqResMessage("Thanks for confirming. Your preferences have been updated.")
                                  setUnsubscribeConfirmedEvent(consentAccepted)
                                } else {
                                  setOptOutReqResMessage("The email you have entered does not exist in our database.")
                                }
                                // Reset form
                                resetForm()
                              })
                              .catch(function () {
                                setSuccessView(true)
                                setOptOutReqResMessage("Internal server error. Please try again later.")
                                isSubmitBlocked = false
                                // Reset form
                                resetForm()
                              })
                              setSubmitting(false)
                            }
                          }}
                        >

                          {({ errors, submitCount }) => {

                            return (
                              <Form id="frm-contact-form" autoComplete="off" noValidate="noValidate">

                              {!successView ? (
                                <>

                                <div className="row">
                                  <div className="col-12 col-md-8 col-lg-6 form-group control-group">
                                    {/* Email */}
                                    <Field type="email" id="form-email" className="form-control" name="email" placeholder={"Type your email"} />

                                    {submitCount > 0 && errors.email !== "" ? (
                                      <>

                                      <div className="help-block with-errors">
                                        <ul role="alert" className={"list-unstyled " + (!errors.email ? "d-none" : "")}>
                                          <li>{errors.email}</li>
                                        </ul>
                                      </div>

                                      </>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-8 col-lg-6 form-group form-text-field form-btn-container">
                                    {/* Submit Btn */}
                                    <button className="form-btn" type="submit" id="submit-contact-form">
                                      {"CONFIRM"}
                                    </button>
                                  </div>
                                </div>

                                </>

                              ) : (
                                <>

                                <div className="row">
                                  <div className="col-12">
                                    <p>{optOutReqResMessage}</p>
                                  </div>
                                </div>

                                </>
                              )}

                              </Form>
                            )

                          }}
                        </Formik>
                        {/* End Formik Form */}
                        </>

                      }

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
  )
}

export const query = graphql`
  query UnsubscribePageQuery($uid: String, $id: String, $lang: String){
    prismicUnsubscribePage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        banner_background {
          alt
          url
        }
        social_card {
          url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        content_title {
          text
        }
        content_description {
          text
        }
      }
    }
  }
`

export default withPrismicPreview(UnsubscribePage)