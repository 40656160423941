import React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicRichText } from "@prismicio/react";
import Masonry from 'react-masonry-css'

// assets
import { getButtonCustomStyles } from "../../assets/js";
import { getFontSize } from "../../assets/js";
import useWindowSize from "../../hooks/useWindowSize";

// constants
import { COLOR } from "../../constants/colors";
import {AnchorLink} from "../../components/AnchorLink";

export const MasonryGallery = ({ slice }) => {
  // console.log("MasonryGallery Slice", slice)
  const { width } = useWindowSize()

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showSubTitle = slice.primary.show_subtitle
  const showTitle = slice.primary.show_title
  const showParagraph = slice.primary.show_paragraph
  const showCTAButton = slice.primary.show_cta_button
  const showGallery = slice.primary.show_gallery

  // Text
  const subTitle = slice.primary.subtitle?.richText || []
  const subTitleTextColor =slice.primary.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = slice.primary.subtitle_font_size_desktop || '38'
  const subTitleFontSizeMobile = slice.primary.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextFontSizeDesktop = slice.primary.title_font_size_desktop || '66'
  const titleTextFontSizeMobile = slice.primary.title_font_size_mobile || '40'
  const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)

  const paragraph = slice.primary.paragraph?.richText || []
  const paragraphTextColor = slice.primary.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = slice.primary.paragraph_font_size_desktop || '24'
  const paragraphFontSizeMobile = slice.primary.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const ctaFontSizeDesktop = slice.primary.cta_font_size_desktop || '24'
  const ctaFontSizeMobile = slice.primary.cta_font_size_mobile || '20'
  const ctaFontSize = getFontSize(width, ctaFontSizeDesktop, ctaFontSizeMobile)

  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  const ctaButtonFontSize = {
    fontSize: ctaFontSize
  }

  // CTA Button
  const ctaButton = {
    text: slice.primary.cta_button_text?.text || '',
    url: slice.primary.cta_button_link?.url || ''
  }

  // CTA Styles
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color,
    bg: slice.primary.cta_button_bg_color,
    bgT: slice.primary.cta_button_bg_color_transparency,
    border: slice.primary.cta_button_border_color,
    colorHover: slice.primary.cta_button_text_color_hover,
    bgHover: slice.primary.cta_button_bg_color_hover,
    bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
    borderHover: slice.primary.cta_button_border_color_hover,
    borderRadius: slice.primary.cta_button_border_radius || "0",
  }


  const { ctaButtonStyle, ctaButtonStyleHover} = getButtonCustomStyles(buttonColorSet)

  // Section
  const sectionStyles = {
    backgroundColor: slice.primary.section_bg_color || COLOR.BLACK
  }

  // Gallery breakpoint cols
  const breakpointColumns = {
    default: 3,
    768: 2
  }

  return (
    <section 
      className="masonry-gallery" 
      style={sectionStyles} 
      id={`masonry-gallery-${sectionId}`}
    >
        { anchorLink && <AnchorLink link={anchorLink}/> }
        { showSubTitle && <h4 className='masonry-gallery__subtitle'>
          { subTitle.map((row) => {
            return  <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>})
          }
        </h4> }

        { showGallery &&
          <Masonry
            breakpointCols={breakpointColumns}
            className="masonry-gallery__img-container"
            columnClassName="masonry-gallery__img-column"
          >
            {slice.items.map((item, index) => (
              <div className='masonry-gallery__img-wrapper' key={index}>
                <img key={index} className="masonry-gallery__img" src={width < 768 ? item.gallery_image_item?.thumbnails.mobile?.url : item.gallery_image_item?.url} alt={width < 768 ? item.gallery_image_item?.thumbnails.mobile?.alt : item.gallery_image_item?.alt} /> 
              </div>
            ))}
          </Masonry>
        }

        { showTitle && <h3 className='masonry-gallery__title'>
          { title.map((row) => {
            return  <span key={row.text} style={titleStyles}>
              <PrismicRichText field={[row]}/><br/>
            </span>})
          }
        </h3> }

        { showParagraph && <p className='masonry-gallery__paragraph'>
          { paragraph.map((row) => {
            return  <span key={row.text} style={paragraphStyles}>{row.text}{paragraph.length > 1 ? <br/> : null}</span>})
          }
          
          {showCTAButton && <Link to={ctaButton.url} title={ctaButton.text} className="masonry-gallery__cta-button" style={{...ctaButtonStyle, ...ctaButtonFontSize}}>
              {ctaButton.text}
          </Link>}
        </p>}
    </section>
)
}

export const fragment = graphql`
  fragment PageDataBodyMasonryGallery on PrismicGenericPageDataBodyMasonryGallery  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_button_link {
        url
      }
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_font_size_desktop
      cta_font_size_mobile
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
      section_bg_color
      show_cta_button
      show_paragraph
      show_title
      show_subtitle
      show_gallery
      subtitle{
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
    }
    items {
      gallery_image_item {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url
        alt
      }
    }
  }
`;