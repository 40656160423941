import React, { useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from "@prismicio/react";
import { useLocation } from "@reach/router";

import {downloadMedia, getButtonCustomStyles, getFontSize} from "../../assets/js";
import useWindowSize from "../../hooks/useWindowSize";

// constants
import { COLOR } from "../../constants/colors";
import CustomLink from "../../components/CustomLink";

// import Swiper core and required modules
import { Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {AnchorLink} from "../../components/AnchorLink";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import { useConsent } from '../../components/ConsentContext';

import { setBuyNowClickedEvent, setDownloadClickedEvent } from '../../assets/js/data-layer';

export const ProductCarousel = ({ slice, context }) => {
    const location = useLocation()
    const { width } = useWindowSize()
    const playerRef = useRef()

    const sectionId = slice.primary.section_id?.text || slice.id
    const anchorLink = slice.primary.anchor_link?.text || ''

    // Toggles
    const showSubTitle = slice.primary.show_subtitle
    const showTitle = slice.primary.show_title
    const showParagraph = slice.primary.show_paragraph
    const showCTAButton = slice.primary.show_cta_button

    const productType = slice.primary.product_type

    // Links
    const isDownloadLink = slice.primary.cta_download_button
    const isTargetBlank = slice.primary.cta_button_open_in_new_tab
    const target = isTargetBlank ? '_blank' : '_self'

    // Text
    const subTitle = slice.primary.subtitle?.richText || []
    const subTitleTextColor =slice.primary.subtitle_text_color || COLOR.BLACK
    const subTitleFontSizeDesktop = slice.primary.subtitle_font_size_desktop || '38'
    const subTitleFontSizeMobile = slice.primary.subtitle_font_size_mobile || '20'
    const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

    const title = slice.primary.title?.richText || []
    const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
    const titleTextFontSizeDesktop = slice.primary.title_font_size_desktop || '44'
    const titleTextFontSizeMobile = slice.primary.title_font_size_mobile || '24'
    const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)

    const paragraph = slice.primary.paragraph?.richText || []
    const paragraphTextColor = slice.primary.paragraph_text_color || COLOR.BLACK
    const paragraphFontSizeDesktop = slice.primary.paragraph_font_size_desktop || '24'
    const paragraphFontSizeMobile = slice.primary.paragraph_font_size_mobile || '20'
    const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

    // CTA Button
    const ctaButtonText = slice.primary.cta_button_text?.text || ''
    const ctaDownloadButtonFileName = slice.primary.cta_download_file_name || ''

    // CTA Button Link
    const ctaButtonLink = slice.primary.cta_button_link?.url || ''
    const ctaButtonDownloadLink = slice.primary.cta_download_button_file?.url || ''

    const subTitleStyles = {
        color: subTitleTextColor,
        fontSize: subTitleFontSize
    }

    const titleStyles = {
        color: titleTextColor,
        fontSize: titleTextFontSize
    }

    const paragraphStyles = {
        color: paragraphTextColor,
        fontSize: paragraphFontSize
    }

    // CTA Styles
    const buttonColorSet = {
        color: slice.primary.cta_button_text_color,
        bg: slice.primary.cta_button_bg_color,
        bgT: slice.primary.cta_button_bg_color_transparency,
        border: slice.primary.cta_button_border_color,
        colorHover: slice.primary.cta_button_text_color_hover,
        bgHover: slice.primary.cta_button_bg_color_hover,
        bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
        borderHover: slice.primary.cta_button_border_color_hover,
        borderRadius: slice.primary.cta_button_border_radius || "0",
    }

    const {ctaButtonStyle} = getButtonCustomStyles(buttonColorSet)

    // CTA Styles
    const ctaMobButtonStyle = {
        color: slice.primary.cta_mobile_button_text_color || COLOR.WHITE,
        backgroundColor: slice.primary.cta_mobile_button_bg_color || COLOR.BLACK,
    }

    // image
    const image = {
        src: width < 768 ? slice.primary.banner?.thumbnails?.mobile.url : slice.primary.banner?.url,
        alt: slice.primary.banner?.alt || ''
    }

    // video
    const githubVideoFilename = width < 768 ? slice.primary?.github_video_filename_mobile : slice.primary?.github_video_filename_desktop;
    const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
    const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''

    // swiper component
    const navigation = {
        nextEl: '.swiper-next-custom', prevEl: '.swiper-prev-custom'
    }

    const swiperBreakPoints = {
        0: {
          slidesPerView: 1
        }, 768: {
          slidesPerView: 2
        }, 992: {
          slidesPerView: 3
        }, 1200: {
          slidesPerView: 4
        },
      };
    
    useEffect(() => {
        if (!playerRef?.current) return

        playerRef.current.addEventListener('suspend', () => {
        playerRef.current?.play()
        });

    }, [])

    const onCTABtnClick = (url, name, consent) =>{
        const dataLayer = { url, name: name.toLowerCase()}
        setBuyNowClickedEvent(dataLayer,consent)
      }
  const consentAccepted = useConsent()

    return(
        <section className='product-carousel py-5'
            id={`product-range-${sectionId}`}
        >
            { anchorLink && <AnchorLink link={anchorLink}/> }

            <div className='product-carousel__text-wrap text-center'>
                { showSubTitle && <h4 className='product-carousel__subtitle'>
                    { subTitle.map((row) => {
                        return  <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>})
                    }
                </h4> }

                { showTitle && <h3 className='product-carousel__title'>
                    { title.map((row) => {
                        return  <span key={row.text} style={titleStyles}>
                            <PrismicRichText field={[row]}/><br/>
                        </span>})
                    }
                </h3> }

                { showParagraph && <p className='product-carousel__paragraph'>
                    { paragraph.map((row) => {
                        return  <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>})
                    }
                </p>}

                <div className={`product-carousel__content ${productType} mt-5 container-fluid px-0`}>
                    <Swiper
                        modules={[Navigation]}
                        navigation={navigation}
                        initialSlide={0}
                        speed={500}
                        breakpoints={swiperBreakPoints}
                    >
                        {slice.items.map((item, i) => {
                            const productCarouselContent =  <div className='product-carousel-content__slide'>
                                {item.product_image && <div className='product-carousel-content__image-wrapper'>
                                    <img
                                        className='product-carousel-content__image'
                                        src={item.product_image?.url}
                                        alt={item.product_image?.alt}
                                    />
                                </div>}
                                {item.product_name && <div className="prodduct-carousel-content__name mt-4">
                                    <div className="product-carousel-content__name-text">
                                        {item.product_name.richText.map((flavor) => {
                                          return <div key={item.text}><PrismicRichText field={[flavor]} /></div>
                                        })}
                                    </div>
                                </div>}
                            </div>

                            return (
                                <SwiperSlide key={`item-${i}`}>
                                    {item.product_link?.url?
                                    <CustomLink to={item.product_link?.url}>{ productCarouselContent }</CustomLink> : productCarouselContent}
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    <button className="swiper-prev-custom">
                        <span className="visually-hidden">Prev</span>
                        <FontAwesomeIcon icon={faChevronLeft} className="fa-solid" size="2xl" />
                    </button>
                    <button className="swiper-next-custom">
                        <span className="visually-hidden">Next</span>
                        <FontAwesomeIcon icon={faChevronRight} className="fa-solid" size="2xl" />
                    </button>
                </div>


                {showCTAButton && <>
                    { !isDownloadLink && <a href={ctaButtonLink} title={ctaButtonText} className="product-carousel__cta-button mt-5"
                                            style={width < 768? ctaMobButtonStyle : ctaButtonStyle} target={target}
                                            onClick={()=> onCTABtnClick(ctaButtonLink,  ctaButtonText, consentAccepted)}
                                            >
                        { ctaButtonText }
                        { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
                    </a> }

                    { isDownloadLink && <button title={ctaButtonText} className="product-carousel__cta-button mt-5"
                                                style={width < 768? ctaMobButtonStyle : ctaButtonStyle}
                                                onClick={()=> {
                                                    downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                                                    // 'download_clicked' data layer event
                                                    setDownloadClickedEvent({
                                                        fileName: ctaDownloadButtonFileName,
                                                        clickUrl: ctaButtonDownloadLink,
                                                        clickText: ctaButtonText,
                                                        pageUrl: location.href
                                                    }, consentAccepted)
                                                }}
                    >
                        { ctaButtonText }
                        <FontAwesomeIcon icon={faDownload} className='ml-2'/>
                    </button> }
                 </>
                }
            </div>

            {image?.src && <img src={image.src} alt={image.alt} className='product-carousel__banner' />}
            
        </section>
    )
}

export const fragment = graphql`
    fragment PageDataBodyProductCarousel on PrismicGenericPageDataBodyProductCarousel {
        id
        primary {
            section_id{
                text
            }
            anchor_link{
                text
            }
            banner {
                thumbnails {
                    mobile {
                        alt
                        url
                    }
                }
                url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
                alt
            }
            product_type
            paragraph {
                text
                richText
            }
            paragraph_text_color
            paragraph_font_size_desktop
            paragraph_font_size_mobile
            show_paragraph
            show_title
            show_subtitle
            subtitle{
                text
                richText
            }
            subtitle_text_color
            subtitle_font_size_desktop
            subtitle_font_size_mobile
            title {
                text
                richText
            }
            title_text_color
            title_font_size_desktop
            title_font_size_mobile
            show_cta_button
            cta_button_bg_color
            cta_button_bg_color_hover
            cta_button_border_color
            cta_button_border_radius
            cta_button_border_color_hover
            cta_mobile_button_bg_color
            cta_mobile_button_text_color
            cta_button_text {
                text
                richText
            }
            cta_button_link {
                url
            }
            cta_button_text_color
            cta_button_text_color_hover
            cta_button_bg_color_transparency
            cta_button_bg_color_hover_transparency
            cta_button_open_in_new_tab
            cta_download_button
            cta_download_button_file {
              url
            }
            cta_download_file_name
        }
        items {
            product_image {
              url
              alt
            }
            product_link{
                url
            }
            product_name{
                text
                html
                richText
            }
        }
    }
`