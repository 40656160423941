import { BasicBanner } from "./BasicBanner";
import { AdPanel } from "./AdPanel";
import { PromoModule } from "./PromoModule";
import { HalfTextHalfImage } from "./HalfTextHalfImage";
import { BasicTextContent } from "./BasicTextContent";
import { EventDetails } from "./EventDetails";


export const components = {
  basic_banner: BasicBanner,
  ad_panel: AdPanel,
  promo_module: PromoModule,
  half_text_half_image: HalfTextHalfImage,
  basic_text_content: BasicTextContent,
  event_details: EventDetails
}