import React from 'react'
import { graphql } from 'gatsby'
import {AnchorLink} from "../../components/AnchorLink";

// assets
import useWindowSize from "../../hooks/useWindowSize";

// constants
import { COLOR } from "../../constants/colors";


export const FullWidthSectionDivider = ({ slice }) => {
  const { width } = useWindowSize()

  const sectionId = slice.primary._id || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showImage = slice.primary.show_background_image


  // Section

  const section = {
    bgColor: slice.primary.background_color || COLOR.WHITE,
    mobileBgImg: showImage && slice.primary?.mobile_background_image?.url || '',
    desktopBgImg: showImage && slice.primary?.desktop_background_image?.url || '',
    marginTop: slice.primary?.margin_top || '5',
    marginBottom: slice.primary?.margin_bottom || '5',
    paddingTop:slice.primary?.padding_top || '0',
    paddingBottom:slice.primary?.padding_bottom || '0',
    heightDesktop: slice.primary?.desktop_height || '200' ,
    heightMobile: slice.primary?.mobile_height || '150'
  }

  const isMobile = width <= 768;
  const heightValue = isMobile ? section.heightMobile : section.heightDesktop;
  const defaultHeight = `${heightValue}px` || '200px';

  const sectionStyles = {
    minHeight: defaultHeight,
    marginTop: `${section.marginTop}px`,
    marginBottom: `${section.marginBottom}px`,
    width:`100%`,
    height:'100%'
  }
  const sectionBackground = {
    background : showImage ? (section.desktopBgImg?`${isMobile ? `url('${section.mobileBgImg}')` : `url('${section.desktopBgImg}')`}` : null) : (section.bgColor?section.bgColor:`#fff`),
    minHeight: defaultHeight,
    height:'100%',
    paddingTop:`${section.paddingTop}px`,
    paddingBottom:`${section.paddingBottom}px`,
    
  }
  return (
    <section  style={sectionStyles} id={`full-width-section-divider-${sectionId}`}>
      { anchorLink && <AnchorLink link={anchorLink}/> }
      <div className = "inside-section-divider" style={sectionBackground}></div>
    </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyFullWidthSectionDivider on PrismicGenericPageDataBodyFullWidthSectionDivider  {
    id
    primary {
      background_color
      show_background_image
      desktop_height
      margin_bottom
      margin_top
      mobile_height
      padding_bottom
      padding_top
      mobile_background_image {
        alt
        url
      }
      desktop_background_image{
        alt
        url
      }
    }
    slice_type
  }
`;