const VALIDATION_EN = {
  provideEmail: "Please provide an email.",
  provideValidEmail: "Please provide a valid email",
  email: "Email*",
  selectTopic: "Please select a topic.",
  provideFirstName: "Please provide a first name.",
  provideLastName: "Please provide a last name.",
  tooShort: "Too Short!",
  tooLong: "Too Long!",
  onlyAlpha: "Only alphabetic characters are allowed.",
  enterBday: "Please enter your birthday.",
  validBday: "Expected birthday format: MM/DD/YYYY",
  validBdayYearFirst: "Expected birthday format: YYYY-MM-DD",
  provideMsg: "Please provide a message.",
  provideBatchCode: "Please provide a batch code.",
  batchValidationChar_1: "Letters only from A to M, excl: I",
  batchValidationChar_2: "Numbers only 0 or 1 or 2 or 3",
  batchValidationChar_3_or_5: "Numbers only from 0 to 9",
  batchValidationChar_4: "Numbers only 2 or 3",
  batchValidationChar_6_or_7: "No special characters",
  batchValidationChar_8_or_9: "No number or special characters",
  provideTimeStamp: "Please provide a time stamp.",
  timeStampMinLeng: "Expected value ex: 12:55",
  lineNumberMinLeng: "Expected value ex: A2",
  batchNumberMinLeng: "Expected value ex: K1522L9GL",
  provideLineNumber: "Please provide a line number.",
  lineNumberValidationFormat: "No special characters",
  pleaseSelectStateOfPurchase: "Please select State of Residence.",
  pleaseSelectPackageType: "Please select package type.",
  selectPackageQuantity: "Please select package quantity.",
  provideFlavorName: "Please provide a flavor name.",
  dontHaveBatchNumber: "I don't have batch number",
  enterProductDetails: "Please enter product details",
  selectState: "Select State of Residence* ",
  messageYes: "Your message has been received <br> and we'll respond as soon as we can.",
  messageNo: "It seems that our mail server is not responding. <br> Please try again later!",
  pleaseSelectTopic: "Please select a topic*",
  qualityConcern: "Quality Concern",
  cantFindProducts: "Can't Find Products",
  sponsorRequest: "Sponsorship Request",
  partnerMedia: "Partnership/Media Inquiry",
  ingredientNutr: "Ingredients/Nutrition Info",
  coupon: "Coupons/Rebates",
  prodFlavFeedback: "Product/Flavor Feedback",
  expDate: "Expiration Date",
  intDisReq: "International Distribution Request",
  distInfoReq: "Distributor Info Request",
  sweepsContInq: "Sweeps/Contest Inquiry",
  other: "Other",
  batchNumberDD: "Batch Number*",
  timeStampDD: "Time Stamp*",
  lineNumberDD: "Line Number*",
  naDD: "N/A",
  selectPackageTypeDD: "Select Package Type*",
  canDD: "Can",
  packageQuantityDD: "Select Package Quantity*",
  singleDD: "Single",
  sixPackDD: "6 pack",
  eightPackDD: "8 pack",
  twelvePackDD: "12 pack",
  twentyFourPackDD: "24 pack",
  flavorNameDD: "Flavor Name*",
  firstNameDD: "First Name*",
  lastNameDD: "Last Name*",
  birthDateDD: "Birthdate* MM/DD/YYYY*",
  birthDateYearFirstDD: "Birthdate* YYYY-MM-DD*",
  msgDD: "Message*",
  signUpWave: "Sign me up to the Wave!",
  submitBtn: "SUBMIT",
  qualityTxt01: "Batch number can be found under any can.",
  qualityTxt02: "Once you have found the batch number, you will be able to derive the production date. Our products should be consumed within one year of the production date. From there, carbonation, sweetness and robustness may start to diminish.",
  expirationTxt01: "You can determine the freshness of our products by reading the batch number, which can be found under any can.",
  expirationTxt02: "Once you have found the batch number, you will be able to derive the production date. Our products should be consumed within one year of the production date. From there, carbonation, sweetness and robustness may start to diminish.",
  rebateTxt01: "For all rebate related questions, you can reach our third-party rebate team at 877-322-8355. Please call them with further questions. When reaching, please include the submission number and purchase date. ",
  rebateTxt02: "To check on the status of your rebate, please visit: ",
  rebateTxt03_A: "For mail-in rebates:",
  rebateTxt03_B: "Just complete the rebate form, and mail it to this address, along with your original receipt​!",
  rebateTxt03_C1: "White Claw Seltzer Works Attn Inmar Rebate Services",
  rebateTxt03_C2: "​PO BOX 426008",
  rebateTxt03_C3: "​Laredo, TX 78044-6008",
  rebateTxt03_D: "For digital offers, the offer code and instructions can be found in store, if it is still valid in your state",
  cantFindTxt01_A: "Use our",
  cantFindTxt01_B: "for help to find your favorite flavor!",
  cantFindLocatorUrlTxt: "product locator",
  nutritionTxt01_A: "Please be sure to check out our",
  nutritionTxt01_B: "section for more information on ingredients and nutrition.",
  nutritionFaqUrlTxt: "FAQ",
  sponsorTxt01: "Thank you for considering us for your sponsorship request.",
  sponsorTxt02: "Due to the large volume of sponsorship related inquiries, only chosen requests will be contacted.",
  partnerTxt01: "Thank you for considering us for a partnership/media opportunity.",
  partnerTxt02: "Due to the large volume of partnership/media related inquiries, only chosen requests will be contacted.",
  viewImage: "VIEW IMAGE",
  decipherBatch: "How to decipher batch number",

  dontSellInfo: "Do Not Sell or Share My Personal Information",
  deleteMyPersonalInfo: 'Delete My Personal Information',
  accessMyPersonalInfo: 'Access My Personal Information',
  correctMyPersonalInfo: 'Correct My Personal Information',
  transferMyPersonalInfo: 'Transfer My Personal Information',
}


const VALIDATION_ES = {
  provideEmail: "Por favor, proporciona un correo electrónico válido",
  provideValidEmail: "Por favor, proporciona un correo electrónico válido",
  email: "Correo electrónico*",
  selectTopic: "Por favor, selecciona un tema",
  provideFirstName: "Por favor, proporciona un nombre",
  provideLastName: "Por favor, proporciona un apellido",
  tooShort: "¡Demasiado corto!",
  tooLong: "¡Demasiado largo!",
  onlyAlpha: "Solo se permiten caracteres alfabéticos.",
  enterBday: "Por favor, introduzca su fecha de nacimiento.",
  validBday: "Formato aceptado de Fecha de Nacimiento: MM/DD/AAAA",
  provideMsg: "Por favor proporciona un mensaje.",
  provideBatchCode: "Por favor, proporciona un número de lote.",
  batchValidationChar_1: "Solo las letras A a la M, excluyendo la I",
  batchValidationChar_2: "Solo los números 0 o 1 o 2 o 3",
  batchValidationChar_3_or_5: "Solo un número del 1 al 9",
  batchValidationChar_4: "Solo los números 2 o 3",
  batchValidationChar_6_or_7: "Solo Letras o Números.",
  batchValidationChar_8_or_9: "Solo letras.",
  provideTimeStamp: "Por favor indique la Hora de Producción.",
  timeStampMinLeng: "Por ejemplo: 12:55",
  lineNumberMinLeng: "Por ejemplo: A2",
  batchNumberMinLeng: "Ejemplo de código de producción: K1522L9GL",
  provideLineNumber: "Por favor indique el número de línea de envasado.",
  lineNumberValidationFormat: "Solo Letras o Números.",
  pleaseSelectStateOfPurchase: "Por favor, seleccionar estado de compra",
  pleaseSelectPackageType: "Por favor, selecciona el tipo de presentación",
  selectPackageQuantity: "Por favor, selecciona el número de lata por caja",
  provideFlavorName: "Por favor, proporciona el nombre del sabor",
  dontHaveBatchNumber: "No tengo número de lote",
  enterProductDetails: "Por favor, ingresa los detalles del producto",
  selectState: "Seleccionar estado de compra*",
  messageYes: "Hemos recibido tu mensaje <br>y te contestaremos en breve.",
  messageNo: "Parece que nuestro servidor de correo no responde. <br>Por favor, inténtalo de nuevo más tarde.",
  pleaseSelectTopic: "Selecciona una opción*",
  qualityConcern: "Problema de calidad",
  cantFindProducts: "No se pueden encontrar los productos",
  sponsorRequest: "Solicitud de patrocinio",
  partnerMedia: "Pregunta de colaboración/prensa",
  ingredientNutr: "Ingredientes/Información Nutricional",
  coupon: "Cupones/Reembolsos",
  prodFlavFeedback: "Comentario sobre producto/sabor",
  expDate: "Fecha de caducidad",
  intDisReq: "Solicitud de distribución internacional",
  distInfoReq: "Solicitud de información del distribuidor",
  sweepsContInq: "Pregunta sobre sorteos/concursos",
  other: "Otro",
  batchNumberDD: "Número de lote*",
  timeStampDD: "Hora de Producción*",
  lineNumberDD: "Línea de Envasado*",
  naDD: "N/A",
  selectPackageTypeDD: "Seleccionar estado de compra*",
  canDD: "Lata",
  packageQuantityDD: "Seleccionar cantidad contenida en el paquete*",
  singleDD: "Individual",
  sixPackDD: "Paquete de 6",
  eightPackDD: "Paquete de 8",
  twelvePackDD: "Paquete de 12",
  twentyFourPackDD: "Paquete de 24",
  flavorNameDD: "Nombre del sabor*",
  firstNameDD: "Nombre*",
  lastNameDD: "Apellido*",
  birthDateDD: "Fecha de nacimiento* MM/DD/AAAA.",
  msgDD: "Mensaje*",
  signUpWave: "Sign me up to the Wave!",
  submitBtn: "ENVIAR",
  qualityTxt01: "El número de lote se puede encontrar debajo de cada lata.",
  qualityTxt02: "Una vez que haya encontrado el número de lote, podrá extraer la fecha de producción. Nuestros productos deben consumirse en el plazo de un año a partir de la fecha de producción. A partir de ahí, la carbonatación, la dulzura y la intensidad de sabor pueden comenzar a disminuir.",
  expirationTxt01: "Puede determinar la frescura de nuestros productos leyendo el número de lote, que se encuentra debajo de cualquier lata.",
  expirationTxt02: "Una vez que haya encontrado el número de lote, podrá extraer la fecha de producción. Nuestros productos deben consumirse en el plazo de un año a partir de la fecha de producción. A partir de ahí, la carbonatación, la dulzura y la intensidad de sabor pueden comenzar a disminuir.",
  rebateTxt01: "Para todas las preguntas e inquietudes relacionadas con el reembolso, comuníquese con Inmar directamente en",
  rebateTxt02: "Para verificar el estado de su reembolso, visite:",
  rebateTxt03_A: "Debido a las estrictas normas que rigen el consumo de alcohol, legalmente no podemos enviar cupones desde nuestra oficina corporativa. Puede encontrar cupones en",
  rebateTxt03_B: "una aplicación de cupones gratuita que puede descargar en su dispositivo Apple o Android.",
  cantFindTxt01_A: "¡Use nuestro",
  cantFindTxt01_B: "para obtener ayuda para encontrar su sabor favorito!",
  cantFindLocatorUrlTxt: "localizador de productos",
  nutritionTxt01_A: "Asegúrese de consultar nuestra sección",
  nutritionTxt01_B: "para obtener más información sobre nuestros ingredientes y el valor nutricional de nuestro productos.",
  nutritionFaqUrlTxt: "Preguntas frecuentes",
  sponsorTxt01: "Gracias por considerarnos para su solicitud de patrocinio.",
  sponsorTxt02: "Debido al gran volumen de consultas relacionadas con patrocinio, solo se contactarán las solicitudes seleccionadas.",
  partnerTxt01: "Gracias por considerarnos para una colaboración o promoción mediática.",
  partnerTxt02: "Debido al gran volumen de consultas relacionadas con ofertas de colaboración y promociones mediáticas, solo se contactarán las solicitudes seleccionadas.",
  viewImage: "VER IMAGEN",
  decipherBatch: "Cómo descifrar el número de lote",

  dontSellInfo: "Do Not Sell or Share My Personal Information",
  deleteMyPersonalInfo: 'Delete My Personal Information',
  accessMyPersonalInfo: 'Access My Personal Information',
  correctMyPersonalInfo: 'Correct My Personal Information',
  transferMyPersonalInfo: 'Transfer My Personal Information',
}

export  { VALIDATION_EN, VALIDATION_ES }