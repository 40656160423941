// core
import * as React from 'react'
import parse from 'html-react-parser'
import {Link} from "gatsby";
import { useConsent } from '../../components/ConsentContext';
import { setBuyNowClickedEvent } from '../../assets/js/data-layer';

export const ProductLocator = ({ slice }) => {
  const data = slice.primary;
  const items = slice.items;

  const onBuyNowClick = (url, name, consent) =>{
    const dataLayer = { url, name: name.toLowerCase()}
    setBuyNowClickedEvent(dataLayer,consent)
  }
  const consentAccepted = useConsent()

  return (
      <>
        <div className="product-locator">
          {data.locator_title.text && <h2 className="product-locator__title">{parse(data.locator_title.text)}</h2>}
          <Link
              to={data.locator_button_link?.url || ''}
              target="_blank"
              title={data.locator_button_text}
              className="button-black product-locator__button my-5"
              onClick={()=> onBuyNowClick(data.locator_button_link?.url,  data.locator_button_text, consentAccepted)}
          >
            {data.locator_button_text}
          </Link>
          {data.locator_description.text && <div className="product-locator__description">{parse(data.locator_description.text)}</div> }
          <div className="product-locator__info row">
            {items.map((element, index) => {
              return (
                  <div key={index} className="product-locator__item col">
                    {element.locator_type_icon.url && <img className="product-locator__item-icon" src={element.locator_type_icon.url} alt={element.locator_type_icon.alt} />}
                    {element.locator_type_text && <div className="product-locator__item-text">{element.locator_type_text}</div>}
                  </div>
              );
            })}
          </div>
        </div>
      </>
  );
};