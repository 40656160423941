import * as React from 'react'
import { PrismicRichText } from "@prismicio/react";

export const BasicTextContent = ({ slice }) => {
  const titleRows = slice.primary.title?.richText || []
  const paragraphs = slice?.items || []

  return (
      <section className="basic-text-content">
        <div className="basic-text-content__wrap">
          {titleRows.length !== 0 && <h2 className='basic-text-content__title'>
            {titleRows.map((row) => {
              return <span key={row.text}><PrismicRichText field={[row]}/><br/></span>
            })}
          </h2>}
          { paragraphs.length !== 0 && paragraphs.map((p) => {
            const description = p.paragraph?.text || ''
            return <p className='basic-text-content__description' key={description}>{description}</p>})
          }
        </div>
      </section>
  )
}