// core
import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { v4 as uuidv4 } from 'uuid'

// Components
import Header from './Header'
import Footer from './Footer'
import AgeGate from './AgeGate'

const LayoutEmbeddedPage = ({children, activeDocMeta = null, showHeaderFooter = false}) => {
  const [showAgeGate, setShowAgeGate] = useState(false)
  const pID = uuidv4()
  const [cookies, setCookie] = useCookies()
  const [persID, setPersID] = useState('')

  // Get Date
  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 7)
  let aWeekFromCurrentDate = currentDate

  const ageGateValid = () => { setCookie('adult', true,{expires: aWeekFromCurrentDate, path: '/'})}


  useEffect(()=> {

    if(cookies?.adult){
      setShowAgeGate(false)
    } else {
      setShowAgeGate(true)
    }

  },[cookies?.adult])

  useEffect(()=>{
    if(cookies?.persID) {
      setPersID(cookies.persID)
    } else {
      setCookie('persID', pID, { expires: aWeekFromCurrentDate, path: '/' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies.persID])

  return (
      <>
        {/* Header */}
        {showHeaderFooter && <Header customPage={'wishpond'} activeDocMeta={activeDocMeta} persID={persID} />}

        {showAgeGate && <AgeGate setAgeValid={ageGateValid}/>}
        <main>{children}</main>

        {/* Footer */}
        {showHeaderFooter && <Footer activeDocMeta={activeDocMeta} subscribeForm={true} />}
      </>
  )
}

export default LayoutEmbeddedPage