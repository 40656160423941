import React, { useRef,useState,useEffect }from 'react'
import { graphql } from "gatsby";
import { AnchorLink } from "../../components/AnchorLink";
import TileCard from './tileCard/TileCard';
import useWindowSize from "../../hooks/useWindowSize";
import { getButtonCustomStyles } from "../../assets/js";
import { COLOR } from '../../constants/colors';
import { getFontSize } from "../../assets/js";
export const Tiles = ({ slice }) => {
  const cards = slice.items || []
  const componentRef = useRef(null);
  const { width } = useWindowSize()
  const sectionId =  slice?.id
  const anchorLink = slice.primary?.anchor_link?.text || ''
  const isMobile = width <= 768;
  const showMobile = slice.primary?.show_mobile
  const containerRef = useRef(null);
  const cardWrapperRef = useRef(null)
  const [maxTileHeight, setMaxTileHeight] = useState(0);
  const [cardMinHeight ,setCardMinHeight] = useState(550)

  // Button
  const showButton = slice.primary?.show_button
  const ctaButtonLink = slice.primary.cta_button_link?.url || ''
  const ctaButtonText = slice.primary.cta_button_text?.text || ''
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color?slice.primary.cta_button_text_color:COLOR.WHITE,
    bg: slice.primary.cta_button_bg_color?slice.primary.cta_button_bg_color:COLOR.BLACK,
    border: slice.primary.cta_button_border_color?slice.primary.cta_button_border_color:COLOR.WHITE,
    colorHover: slice.primary?.cta_button_text_color_hover || '',
    bgHover: slice.primary?.cta_button_bg_color_hover || '',
    bgHoverT: slice.primary?.cta_button_bg_color_hover_transparency || '',
    borderHover: slice.primary?.cta_button_border_color_hover || '',
    borderRadius: slice.primary?.cta_button_border_radius || "20",
  }
  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)

  //Paragraph
  const showParagraph = slice.primary.show_paragraph
  const paragraph = slice.primary.paragraph?.richText || []
  const paragraphTextColor = slice.primary.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = slice.primary.paragraph_font_size_desktop || '24'
  const paragraphFontSizeMobile = slice.primary.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const marginTop = slice.primary?.margin_top || '20';
  const marginBottom =  slice.primary?.margin_bottom || '20'
  const sectionStyles = {
    marginTop: marginTop + 'px',
    marginBottom:marginBottom + 'px',
  }
  const paragraphPaddingTop = slice.primary.show_paragraph?slice.primary.paragraph_padding_top?slice.primary.paragraph_padding_top:'10':'0'
  const paragraphPaddingBottom = slice.primary.show_paragraph?slice.primary.paragraph_padding_bottom?slice.primary.paragraph_padding_bottom:'10':'0'
  const ctaButtonPaddingTop = slice.primary.show_button?slice.primary.cta_button_padding_top?slice.primary.cta_button_padding_top: '10':'0'
  const ctaButtonPaddingBottom = slice.primary.show_button?slice.primary.cta_button_padding_bottom?slice.primary.cta_button_padding_bottom: '20':'0'
  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }
  const paragraphTileStyles = {
    paddingBottom:paragraphPaddingBottom+'px',
    paddingTop:paragraphPaddingTop+'px'
  }
  const buttonStyle = {
    paddingBottom:ctaButtonPaddingBottom+'px',
    paddingTop:ctaButtonPaddingTop+'px'
  } 
  
  // Text
   useEffect(() => {
    const calculateMinHeight = () => {
      const cardWidth = cardWrapperRef?.current?.offsetWidth;
      const calculatedMinHeight = Math.floor(cardWidth * 1.2); 
      setCardMinHeight(calculatedMinHeight);
    };

    // Call the function initially and on window resize
    calculateMinHeight();
    window.addEventListener('resize', calculateMinHeight);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', calculateMinHeight);
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const tileContents = containerRef.current.querySelectorAll('.tile-content');
      let maxHeight = 0;

      tileContents.forEach(content => {
        maxHeight = Math.max(maxHeight, content.offsetHeight);
      });

      setMaxTileHeight(maxHeight);
    }
  }, []);
  if(isMobile && !showMobile){
    return null
  }
  return (
      <section ref= {componentRef} className='tiles' id={`tiles-${sectionId}`} style={sectionStyles}>
        { anchorLink && <AnchorLink link={anchorLink}/> }
        { showParagraph &&
        <div className='tiles-text-wrap'>
          <p className='tiles__paragraph' style={paragraphTileStyles}>
          { paragraph.map((row, index) => {
            return  <span key={index} style={paragraphStyles}>{row.text}<br/></span>})
          }
        </p>
        </div>
        }
        {showButton &&  <div style={buttonStyle}>
         <a href={ctaButtonLink} title={ctaButtonText} className="tiles__cta-button" style={ctaButtonStyle} target={'_blank'}>
          { ctaButtonText }
        </a> 
        </div>}
        <div className="tile-container" ref={containerRef}>
            {cards.map((card, index) => (
                    <div className="card-wrapper" key={index} ref={cardWrapperRef}>
                    <TileCard
                        image={card?.background_image}
                        title={card?.card_title}
                        subtitle={card?.card_sub_title}
                        link={card?.link}
                        hoverColor={card?.hover_color}
                        minHeight = {maxTileHeight}
                        cardMinHeight = {cardMinHeight}
                        linkText = {card?.link_text}
                    />
                    </div>
                ))}
            </div>
      </section>
  )
}

export const fragment = graphql`
        fragment PageDataBodyTile  on PrismicGenericPageDataBodyTile {
          id
          primary {
          margin_top
          margin_bottom
          cta_button_padding_bottom
          cta_button_padding_top
          paragraph_padding_top
          paragraph_padding_bottom
          show_mobile
          paragraph {
              richText
              html
          }
          paragraph_font_size_desktop
          paragraph_font_size_mobile
          paragraph_text_color
          show_paragraph
          show_button
          cta_button_link {
            url
          }
            anchor_link{
              text
          }
          cta_button_bg_color
          cta_button_bg_color_transparency
          cta_button_bg_color_hover
          cta_button_bg_color_hover_transparency
          cta_button_border_color
          cta_button_border_radius
          cta_button_border_color_hover
          cta_button_text {
            text
            richText
          }
          cta_button_text_color
          cta_button_text_color_hover
          }
          
          items {
            background_image {
              alt
              url
            }
            hover_color
            link {
              id
                url
                target
                type
                uid
                link_type
                tags
                slug
                size
                raw
            }
            link_text
            card_sub_title
            card_title
          }
        }`;



